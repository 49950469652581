import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchContext } from '../Context';

const SearchContextProvider = props => {
  const [query, setQuery] = useState('');

  const searchHandler = query => {
    setQuery(query);
  };

  return (
    <SearchContext.Provider
      value={{ query: query, searchHandler: searchHandler }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

SearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SearchContextProvider;

import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import HelpButton from '../components/custom/HelpButton';
import AddEntryButton from '../components/custom/AddEntryButton';
import ActionModal from '../components/custom/ActionModal';
import DeleteModal from '../components/custom/DeleteModal';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AuthProviderContext, SearchContext } from '../context/Context';
import EmployeeList from '../components/pages/employee/EmployeeList';
import SortButton from '../components/custom/SortButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordGenerator from '../components/custom/PasswordGenerator';
import { toast } from 'react-toastify';

class Employee extends React.Component {
  constructor(props) {
    super(props);
    this.actionModalRef = React.createRef();
    this.deleteEntryModalRef = React.createRef();

    this.state = {
      entries: [],
      entryToDelete: null,
      entryToUpdate: null,
      entry: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      isLoading: false,
      sortDirection: 'asc',
      showPassword: false,
      isDetail: false
    };
  }

  async componentDidMount() {
    await this.fetchEntries();
  }

  async fetchEntries() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    this.setState({ isLoading: true });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/employee/${this.context.user.laboratoryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const entries = await response.data.data;
    this.setState({ entries: entries, isLoading: false });

    this.sortEntries();
  }

  resetState() {
    this.setState({
      entry: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      entryToUpdate: null,
      isDetail: false
    });
  }

  openModal(entry = null, isDetail = false) {
    if (entry) {
      let editEntry = { ...entry };

      let id = editEntry._id;

      delete editEntry['_id'];

      this.setState({
        entry: editEntry,
        entryToUpdate: id
      });
    }

    if (isDetail) {
      this.setState({
        isDetail: true
      });
    }

    this.actionModalRef.current.toggleModal();
  }

  cancelModal() {
    this.resetState();
  }

  async submit() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    if (!this.state.entryToUpdate) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/employee/${this.context.user.laboratoryId}`,
          {
            ...this.state.entry
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async res => {
          if (!res.data.success) {
            toast.error(res.data.message);
          }

          await this.fetchEntries();
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/employee/${this.context.user.laboratoryId}/${this.state.entryToUpdate}`,
          {
            firstName: this.state.entry.firstName,
            lastName: this.state.entry.lastName
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await this.fetchEntries();
        });
    }

    this.resetState();
  }

  openDeleteEntryModal(id) {
    this.setState({ entryToDelete: id });

    this.deleteEntryModalRef.current.toggleModal();
  }

  async deleteEntry() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/employee/${this.context.user.laboratoryId}/${this.state.entryToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(() => {
        this.setState({
          entryToDelete: null
        });

        this.fetchEntries();
      });
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        [name]: value
      }
    }));
  }

  handlePasswordGeneratorChange(password) {
    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        password: password
      }
    }));
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  sortEntries(direction) {
    let dir = this.state.sortDirection;

    if (direction) {
      dir = direction;
    }

    if (dir === 'asc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.firstName + ' ' + a.lastName > b.firstName + ' ' + b.lastName
          ? 1
          : -1;
      });

      this.setState({
        entries: sorted
      });
    } else if (dir === 'desc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.firstName + ' ' + a.lastName > b.firstName + ' ' + b.lastName
          ? -1
          : 1;
      });

      this.setState({
        entries: sorted
      });
    }

    this.forceUpdate();
  }

  changeSortDirection(direction) {
    this.setState({
      sortDirection: direction
    });

    this.sortEntries(direction);
  }

  render() {
    return (
      <div>
        <SearchContext.Consumer>
          {({ query }) => (
            <EmployeeList
              employees={this.state.entries}
              openDeleteModal={id => this.openDeleteEntryModal(id)}
              openUpdateModal={entry => this.openModal(entry)}
              openDetailModal={entry => this.openModal(entry, true)}
              isLoading={this.state.isLoading}
              searchQuery={query}
            />
          )}
        </SearchContext.Consumer>
        <SortButton handleClick={this.changeSortDirection.bind(this)} />
        <HelpButton />
        <AddEntryButton handleClick={() => this.openModal()} />
        <ActionModal
          ref={this.actionModalRef}
          title={
            this.state.entryToUpdate
              ? `Benutzer ${this.state.entryToUpdate} ${
                  this.state.isDetail ? '' : 'editieren'
                }`
              : 'Neuen Benutzer erstellen'
          }
          onSubmit={this.submit.bind(this)}
          onCancel={this.cancelModal.bind(this)}
          size={'md'}
          hasCloseButton={this.state.isDetail}
          hasSubmitButton={!this.state.isDetail}
          hasCancelButton={!this.state.isDetail}
        >
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Vorname</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={this.state.entry.firstName}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe einen Vornamen ein.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Nachname</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  required
                  value={this.state.entry.lastName}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe einen Nachnamen ein.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>E-Mail</Form.Label>
            <Form.Control
              type="email"
              name="email"
              required
              value={this.state.entry.email}
              disabled={this.state.entryToUpdate !== null}
              onChange={this.handleChange.bind(this)}
            />
            <Form.Control.Feedback type="invalid">
              Bitte gebe eine E-Mail Adresse ein.
            </Form.Control.Feedback>
          </Form.Group>
          {!this.state.entryToUpdate && (
            <>
              <Form.Group>
                <Form.Label>Passwort</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type={this.state.showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={this.handleChange.bind(this)}
                    autoComplete="nope"
                    value={this.state.entry.password}
                    required
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      onClick={this.toggleShowPassword.bind(this)}
                      icon={this.state.showPassword ? 'eye-slash' : 'eye'}
                    />
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    Bitte gebe ein Passwort ein.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <PasswordGenerator
                name="passwordGen"
                value={this.state.entry.password}
                onChange={this.handlePasswordGeneratorChange.bind(this)}
              />
            </>
          )}
        </ActionModal>
        <DeleteModal
          ref={this.deleteEntryModalRef}
          onDelete={() => this.deleteEntry()}
        />
      </div>
    );
  }
}

Employee.propTypes = {
  auth0: PropTypes.any
};

Employee.contextType = AuthProviderContext;

export default withAuth0(Employee);

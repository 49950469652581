import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import generic1 from '../../../assets/img/logo.png';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PasswordItem = props => {
  const toggleDetailModal = () => {
    props.openDetailModal(props.access);
  };

  return (
    <Col sm={12} md={6} lg={3} className="my-3">
      <Card
        style={{ width: '15rem' }}
        className="clickable mx-auto mx-md-0"
        onClick={() => toggleDetailModal()}
      >
        <Card.Img src={generic1} variant="top" className="p-3" />
        <Card.Body>
          <h5 className="text-center">{props.access.name}</h5>
          <p className="text-center text-muted small">{props.access.type}</p>
          <Row className="mt-4">
            <Col>
              <Button
                variant="primary"
                size="sm"
                onClick={prevent(() => props.openUpdateModal(props.access))}
              >
                Verwalten
              </Button>
            </Col>
            <Col>
              <Button
                variant="secondary"
                size="sm"
                className="float-end"
                onClick={prevent(() => props.openDeleteModal(props.access._id))}
              >
                <FontAwesomeIcon icon="trash" />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

PasswordItem.propTypes = {
  access: PropTypes.object,
  openDeleteModal: PropTypes.func,
  openUpdateModal: PropTypes.func,
  openDetailModal: PropTypes.func
};

export function prevent(fn, defaultOnly) {
  return (e, ...params) => {
    e && e.preventDefault();
    !defaultOnly && e && e.stopPropagation();
    fn(e, ...params);
  };
}

export default PasswordItem;

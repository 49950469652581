import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import Auth0HistoryProvider from './authentication/Auth0HistoryProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthContextProvider from './authentication/AuthProvider';
import SearchContextProvider from './context/providers/SearchContextProvider';

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <Auth0HistoryProvider>
        <AuthContextProvider>
          <SearchContextProvider>
            <Main>
              <App />
            </Main>
          </SearchContextProvider>
        </AuthContextProvider>
      </Auth0HistoryProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('main')
);

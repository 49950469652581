export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/'
    }
  ]
};
export const mainRoutes = {
  label: 'Main',
  children: [
    {
      name: 'CDS',
      icon: 'database',
      to: '/cds',
      active: true
    },
    {
      name: 'CRM',
      icon: 'users',
      to: '/crm',
      active: true
    },
    {
      name: 'Passwort-Management',
      icon: 'key',
      to: '/password-management',
      active: true
    }
    /*{
      name: 'Order-Management',
      icon: 'clipboard',
      active: true,
      children: [
        {
          name: 'Current Orders',
          to: '/orders/current'
        },
        {
          name: 'Finished Orders',
          to: '/orders/finished'
        },
        {
          name: 'Archived Orders',
          to: '/orders/archived'
        }
      ]
    },
    {
      name: 'Material-Management',
      icon: 'gem',
      to: '/material-management',
      active: true
    },
    {
      name: 'Device-Management',
      icon: 'desktop',
      to: '/device-management',
      active: true
    },
    {
      name: 'Trainings-Videos',
      icon: 'video',
      to: '/trainings-videos',
      active: true
    },
    {
      name: 'QM',
      icon: 'clipboard-check',
      active: true,
      children: [
        {
          name: 'Risikomanagementplan',
          to: '/crm'
        },
        {
          name: 'Riskanalysis',
          to: '/crm'
        },
        {
          name: 'Security Reports',
          to: '/crm'
        },
        {
          name: 'Responsible Person',
          to: '/crm'
        }
      ]
    }*/
  ]
};

export const systemRoutes = {
  label: 'System',
  children: [
    {
      name: 'Benutzer',
      icon: 'hospital-user',
      to: '/users',
      active: true
    },
    {
      name: 'Einstellungen',
      icon: 'cog',
      active: true,
      children: [
        {
          name: 'Allgemein',
          to: '/laboratory',
          active: true
        },
        {
          name: 'CDS Vorlagen',
          to: '/cds-templates',
          active: true
        }
      ]
    }
  ]
};

export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Calendar',
      icon: 'calendar-alt',
      to: '/app/calendar',
      active: true
    },
    {
      name: 'Chat',
      icon: 'comments',
      to: '/app/chat',
      active: true
    },
    {
      name: 'Email',
      icon: 'envelope-open',
      active: true,
      children: [
        {
          name: 'Inbox',
          to: '/email/inbox',
          active: true
        },
        {
          name: 'Email detail',
          to: '/email/email-detail',
          active: true
        },
        {
          name: 'Compose',
          to: '/email/compose',
          active: true
        }
      ]
    },
    {
      name: 'Events',
      icon: 'calendar-day',
      active: true,
      children: [
        {
          name: 'Create an event',
          to: '/events/create-an-event',
          active: true
        },
        {
          name: 'Event detail',
          to: '/events/event-detail',
          active: true
        },
        {
          name: 'Event list',
          to: '/events/event-list',
          active: true
        }
      ]
    },
    {
      name: 'E Commerce',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Product',
          active: true,
          children: [
            {
              name: 'Product list',
              to: '/e-commerce/product/product-list',
              active: true
            },
            {
              name: 'Product grid',
              to: '/e-commerce/product/product-grid',
              active: true
            },
            {
              name: 'Product details',
              to: '/e-commerce/product/product-details',
              active: true
            }
          ]
        },
        {
          name: 'Orders',
          active: true,
          children: [
            {
              name: 'Order list',
              to: '/e-commerce/orders/order-list',
              active: true
            },
            {
              name: 'Order details',
              to: '/e-commerce/orders/order-details',
              active: true
            }
          ]
        },
        {
          name: 'Customers',
          to: '/e-commerce/customers',
          active: true
        },
        {
          name: 'Customer details',
          to: '/e-commerce/customer-details',
          active: true
        },
        {
          name: 'Shopping cart',
          to: '/e-commerce/shopping-cart',
          active: true
        },
        {
          name: 'Checkout',
          to: '/e-commerce/checkout',
          active: true
        },
        {
          name: 'Billing',
          to: '/e-commerce/billing',
          active: true
        },
        {
          name: 'Invoice',
          to: '/e-commerce/invoice',
          active: true
        }
      ]
    },
    {
      name: 'E Learning',
      icon: 'graduation-cap',
      active: true,
      badge: {
        type: 'success',
        text: 'New'
      },
      children: [
        {
          name: 'Course',
          active: true,
          children: [
            {
              name: 'Course list',
              to: '/e-learning/course/course-list',
              active: true
            },
            {
              name: 'Course grid',
              to: '/e-learning/course/course-grid',
              active: true
            },
            {
              name: 'Course details',
              to: '/e-learning/course/course-details',
              active: true
            },
            {
              name: 'Create a course',
              to: '/e-learning/course/create-a-course',
              active: true
            }
          ]
        },
        {
          name: 'Student overview',
          to: '/e-learning/student-overview',
          active: true
        },
        {
          name: 'Trainer profile',
          to: '/e-learning/trainer-profile',
          active: true
        }
      ]
    },
    {
      name: 'Kanban',
      icon: ['fab', 'trello'],
      to: '/app/kanban',
      active: true
    },
    {
      name: 'Social',
      icon: 'share-alt',
      active: true,
      children: [
        {
          name: 'Feed',
          to: '/social/feed',
          active: true
        },
        {
          name: 'Activity log',
          to: '/social/activity-log',
          active: true
        },
        {
          name: 'Notifications',
          to: '/social/notifications',
          active: true
        },
        {
          name: 'Followers',
          to: '/social/followers',
          active: true
        }
      ]
    }
  ]
};

export default [dashboardRoutes, mainRoutes, systemRoutes];

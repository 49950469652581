import React from 'react';
import { Button, Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import TinymceEditor from '../../common/TinymceEditor';
import axios from 'axios';
import PropTypes from 'prop-types';
import { AuthProviderContext } from '../../../context/Context';
import { withAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class CdsTemplateCardStandard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templateId: '',
      sections: [],
      availableSections: [],
      newField: {
        name: '',
        type: 'text',
        content: ''
      }
    };
  }

  async componentDidMount() {
    const availableSections = await this.getAvailableSections();
    const sections = await this.getSectionsValues();

    availableSections.forEach(section => {
      if (!sections.sections.find(s => s.sectionId === section._id)) {
        sections.sections.push({
          sectionId: section._id,
          fields: []
        });
      }
    });

    this.setState({
      templateId: sections._id,
      sections: sections.sections,
      availableSections: availableSections
    });
  }

  async getAvailableSections() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/sections`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.data;
  }

  async getSectionsValues() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customer-data-specifics-template/standard`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.data;
  }

  async saveSectionsValues() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customer-data-specifics-template/${this.state.templateId}`,
      {
        sections: this.state.sections
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.data;
  }

  handleNewFieldChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    this.setState(prevState => ({
      newField: {
        ...prevState.newField,
        [name]: value
      }
    }));
  }

  handleSectionValueChange(value, sectionIndex) {
    const { sections } = this.state;

    sections[sectionIndex] = value;

    this.setState({
      sections: sections
    });
  }

  getValue(sectionId) {
    let section = this.state.sections.find(
      section => section.sectionId === sectionId
    );

    if (section) {
      return section.value;
    }
    return '';
  }

  addField(section_id) {
    let sections = [...this.state.sections];

    let index = sections.findIndex(section => section.sectionId === section_id);

    if (!sections[index].fields) {
      sections[index].fields = [];
    }

    sections[index].fields.push(this.state.newField);

    this.setState(prevState => ({
      ...prevState,
      sections: sections,
      newField: {
        name: '',
        type: 'text',
        content: ''
      }
    }));
  }

  getFieldsBySectionId(section_id) {
    let sections = [...this.state.sections];
    let index = sections.findIndex(section => section.sectionId === section_id);
    let section = sections[index];
    let fields = [];

    if (section.fields) {
      fields = section.fields;
    }

    return fields;
  }

  handleFieldValueChange(value, section_id, fieldIndex) {
    let sections = [...this.state.sections];
    let index = sections.findIndex(section => section.sectionId === section_id);
    let section = sections[index];

    section.fields[fieldIndex].content = value;

    this.setState(prevState => ({
      ...prevState,
      sections: [...prevState.sections]
    }));
  }

  removeField(section_id, fieldIndex) {
    let sections = [...this.state.sections];
    let index = sections.findIndex(section => section.sectionId === section_id);
    let section = sections[index];

    section.fields.splice(fieldIndex, 1);

    this.setState(prevState => ({
      ...prevState,
      sections: [...prevState.sections]
    }));
  }

  render() {
    return (
      <Card className="p-3 mt-3">
        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {this.state.availableSections.map((section, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={index}>{section.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {this.state.availableSections.map((section, index) => (
                  <Tab.Pane eventKey={index} key={index}>
                    {this.getFieldsBySectionId(section._id).map(
                      (field, fieldIndex) => (
                        <div className="mt-3" key={fieldIndex}>
                          <Form.Group className="mb-3">
                            <Form.Label className="w-100">
                              {field.name}
                              <Button
                                size={'sm'}
                                variant={'secondary'}
                                className="float-end"
                                onClick={() =>
                                  this.removeField(section._id, fieldIndex)
                                }
                              >
                                <FontAwesomeIcon size={'sm'} icon={faTrash} />
                              </Button>
                            </Form.Label>
                            {field.type === 'textarea' && (
                              <TinymceEditor
                                value={field.content}
                                handleChange={value =>
                                  this.handleFieldValueChange(
                                    value,
                                    section._id,
                                    fieldIndex
                                  )
                                }
                              />
                            )}
                            {field.type === 'text' && (
                              <Form.Control
                                type="text"
                                value={field.content}
                                onChange={e =>
                                  this.handleFieldValueChange(
                                    e.target.value,
                                    section._id,
                                    fieldIndex
                                  )
                                }
                              />
                            )}
                            {field.type === 'checkbox' && (
                              <Form.Check
                                type="checkbox"
                                label="Trifft zu"
                                onChange={e =>
                                  this.handleFieldValueChange(
                                    e.target.checked,
                                    section._id,
                                    fieldIndex
                                  )
                                }
                                checked={field.content}
                              />
                            )}
                          </Form.Group>
                        </div>
                      )
                    )}
                    <Row className="mt-3">
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={this.state.newField.name}
                            onChange={this.handleNewFieldChange.bind(this)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Typ</Form.Label>
                          <Form.Select
                            name="type"
                            value={this.state.newField.type}
                            onChange={this.handleNewFieldChange.bind(this)}
                          >
                            <option value="text">Text</option>
                            <option value="textarea">Textarea</option>
                            <option value="checkbox">Checkbox</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Button
                          variant="primary"
                          onClick={this.addField.bind(this, section._id)}
                        >
                          Hinzufügen
                        </Button>
                      </Col>
                    </Row>

                    <Button
                      className="mt-3 float-end"
                      variant={'primary'}
                      onClick={this.saveSectionsValues.bind(this)}
                    >
                      Speichern
                    </Button>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card>
    );
  }
}
CdsTemplateCardStandard.propTypes = {
  auth0: PropTypes.any
};

CdsTemplateCardStandard.contextType = AuthProviderContext;

export default withAuth0(CdsTemplateCardStandard);

import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import PasswordItem from './PasswordItem';
import PropTypes from 'prop-types';
import NoResults from '../../../pages/others/NoResults';
import Loading from '../../../authentication/Loading';

const PasswordList = props => {
  const [entries, setEntries] = useState(props.accesses);

  useEffect(() => {
    setEntries(props.accesses);

    filterEntries();
  }, [props.accesses]);

  useEffect(() => {
    filterEntries();
  }, [props.searchQuery]);

  const filterEntries = () => {
    if (props.searchQuery !== '') {
      const filteredEntries = props.accesses.filter(entry => {
        if (
          entry.name.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
          entry?.login
            ?.toLowerCase()
            .includes(props.searchQuery.toLowerCase()) ||
          entry?.url?.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
          entry?.deviceName
            ?.toLowerCase()
            .includes(props.searchQuery.toLowerCase()) ||
          entry?.softwareName
            ?.toLowerCase()
            .includes(props.searchQuery.toLowerCase())
        ) {
          return entry;
        }
      });
      setEntries(filteredEntries);
    } else {
      setEntries(props.accesses);
    }
  };

  return (
    <Row>
      {props.isLoading ? (
        <Loading />
      ) : (
        <>
          {entries.length > 0 ? (
            <>
              {entries.map((access, i) => (
                <PasswordItem
                  key={i}
                  access={access}
                  openDeleteModal={id => props.openDeleteModal(id)}
                  openUpdateModal={access => props.openUpdateModal(access)}
                  openDetailModal={employee => props.openDetailModal(employee)}
                />
              ))}
            </>
          ) : (
            <>
              {props.searchQuery === '' ? (
                <NoResults text="Es sind noch keine Zugänge vorhanden." />
              ) : (
                <NoResults text="Es wurden keine Zugänge gefunden." />
              )}
            </>
          )}
        </>
      )}
    </Row>
  );
};

PasswordList.defaultProps = {
  searchQuery: ''
};

PasswordList.propTypes = {
  accesses: PropTypes.array,
  openDeleteModal: PropTypes.func,
  openUpdateModal: PropTypes.func,
  openDetailModal: PropTypes.func,
  isLoading: PropTypes.bool,
  searchQuery: PropTypes.string
};

export default PasswordList;

import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CrmItem from './CrmItem';
import NoResults from '../../../pages/others/NoResults';
import Loading from '../../../authentication/Loading';

const CrmList = props => {
  const [entries, setEntries] = useState(props.customers);

  useEffect(() => {
    filterEntries();
  }, [props.customers]);

  useEffect(() => {
    filterEntries();
  }, [props.searchQuery]);

  const filterEntries = () => {
    if (props.searchQuery !== '') {
      const filteredEntries = props.customers.filter(entry => {
        if (
          entry?.name
            ?.toLowerCase()
            .includes(props.searchQuery.toLowerCase()) ||
          entry?.phone
            ?.toLowerCase()
            .includes(props.searchQuery.toLowerCase()) ||
          entry?.fax?.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
          entry?.customerNumber
            ?.toLowerCase()
            .includes(props.searchQuery.toLowerCase()) ||
          entry?.email?.toLowerCase().includes(props.searchQuery.toLowerCase())
        ) {
          return entry;
        }
      });
      setEntries(filteredEntries);
    } else {
      setEntries(props.customers);
    }
  };

  return (
    <Row>
      {props.isLoading ? (
        <Loading />
      ) : (
        <>
          {entries.length > 0 ? (
            <>
              {entries.map((customer, i) => (
                <CrmItem
                  key={i}
                  customer={customer}
                  openDeleteModal={id => props.openDeleteModal(id)}
                  openUpdateModal={customer => props.openUpdateModal(customer)}
                  openDetailModal={customer => props.openDetailModal(customer)}
                />
              ))}
            </>
          ) : (
            <NoResults />
          )}
        </>
      )}
    </Row>
  );
};

CrmList.defaultProps = {
  searchQuery: ''
};

CrmList.propTypes = {
  customers: PropTypes.array,
  openDeleteModal: PropTypes.func,
  openUpdateModal: PropTypes.func,
  openDetailModal: PropTypes.func,
  isLoading: PropTypes.bool,
  searchQuery: PropTypes.string
};

export default CrmList;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const HelpButton = () => {
  return (
    <div className="help-button">
      <FontAwesomeIcon icon={faQuestion} />
    </div>
  );
};

export default HelpButton;

import React from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Form } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import PropTypes from 'prop-types';
import { generate } from 'generate-password';
import { xkcdPassphrase } from 'xkcd-passphrase';

class PasswordGenerator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      length: 8,
      upperCase: true,
      lowerCase: true,
      numeric: true,
      symbols: true,
      isSimple: props.isSimple,
      bits: 32
    };
  }

  async generatePassword() {
    let password = '';

    if (!this.state.isSimple) {
      password = generate({
        length: this.state.length,
        symbols: this.state.symbols,
        numbers: this.state.numeric,
        lowercase: this.state.lowerCase,
        uppercase: this.state.upperCase
      });
    } else {
      password = await xkcdPassphrase.generate(this.state.bits);
    }

    this.props.onChange(password);
  }

  handleChange(event) {
    let name = event.target.name;

    this.setState(
      prevState => ({
        ...prevState,
        [name]: !this.state[name]
      }),
      () => {
        this.generatePassword();
      }
    );
  }

  handleRangeChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    this.setState(
      prevState => ({
        ...prevState,
        [name]: value
      }),
      () => {
        this.generatePassword();
      }
    );
  }

  getBitsLabel(value) {
    switch (value) {
      case 32:
        return 'Kurz';
      case 48:
        return 'Mittel';
      case 64:
        return 'Lang';
    }
  }

  render() {
    return (
      <>
        <PasswordStrengthBar className="mt-1" password={this.props.value} />

        <Form.Group>
          <Form.Check
            type="checkbox"
            name="isSimple"
            label="Einfaches Passwort verwenden"
            id="isSimple"
            className="mt-1 mb-4"
            checked={this.state.isSimple}
            onChange={this.handleChange.bind(this)}
          />
        </Form.Group>

        {!this.state.isSimple && (
          <div>
            <Form.Group>
              <Form.Check
                type="checkbox"
                name="symbols"
                label="Mit Sonderzeichen"
                id="symbols"
                checked={this.state.symbols}
                onChange={this.handleChange.bind(this)}
                disabled={
                  !this.state.upperCase &&
                  !this.state.lowerCase &&
                  !this.state.numeric
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                type="checkbox"
                name="upperCase"
                label="Mit Groß-Buchstaben"
                id="upperCase"
                checked={this.state.upperCase}
                onChange={this.handleChange.bind(this)}
                disabled={
                  !this.state.symbols &&
                  !this.state.lowerCase &&
                  !this.state.numeric
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                type="checkbox"
                name="lowerCase"
                label="Mit Klein-Buchstaben"
                id="lowerCase"
                checked={this.state.lowerCase}
                onChange={this.handleChange.bind(this)}
                disabled={
                  !this.state.symbols &&
                  !this.state.upperCase &&
                  !this.state.numeric
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                type="checkbox"
                name="numeric"
                label="Mit Zahlen"
                id="numeric"
                checked={this.state.numeric}
                onChange={this.handleChange.bind(this)}
                disabled={
                  !this.state.symbols &&
                  !this.state.upperCase &&
                  !this.state.lowerCase
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Anzahl der Zeichen</Form.Label>
              <RangeSlider
                name="length"
                value={this.state.length}
                min={1}
                onChange={this.handleRangeChange.bind(this)}
              />
            </Form.Group>
          </div>
        )}

        {this.state.isSimple && (
          <>
            <Form.Group>
              <Form.Label>Länge</Form.Label>
              <RangeSlider
                name="bits"
                value={this.state.bits}
                min={32}
                max={64}
                step={16}
                tooltip={'on'}
                tooltipLabel={value => this.getBitsLabel(value)}
                onChange={this.handleRangeChange.bind(this)}
              />
            </Form.Group>
          </>
        )}
      </>
    );
  }
}

PasswordGenerator.defaultProps = {
  isSimple: false
};

PasswordGenerator.propTypes = {
  password: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isSimple: PropTypes.bool
};

export default PasswordGenerator;

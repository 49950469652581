import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CrmContactPersons = props => {
  const [contactPerson, setContactPerson] = React.useState({});

  useEffect(() => {
    setContactPerson(props.contactPerson);
  }, [props.contactPerson]);

  const handleChange = e => {
    const contactPersonCopy = { ...contactPerson };
    contactPersonCopy[e.target.name] = e.target.value;
    setContactPerson(contactPersonCopy);

    props.handleChange(contactPersonCopy, props.contactPersonIndex);
  };

  const deleteContactPerson = () => {
    props.handleDelete(props.contactPersonIndex);
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={contactPerson.name}
          onChange={handleChange}
          disabled={props.isDetail}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte gebe einen Namen ein.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Telefonnummer</Form.Label>
        <Form.Control
          type="text"
          name="phone"
          value={contactPerson.phone}
          onChange={handleChange}
          disabled={props.isDetail}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte gebe eine Telefonnummer ein.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Handynummer</Form.Label>
        <Form.Control
          type="text"
          name="mobile"
          value={contactPerson.mobile}
          onChange={handleChange}
          disabled={props.isDetail}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>E-Mail Adresse</Form.Label>
        <Form.Control
          type="text"
          name="email"
          value={contactPerson.email}
          onChange={handleChange}
          disabled={props.isDetail}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte gebe eine E-Mail Adresse ein.
        </Form.Control.Feedback>
      </Form.Group>
      {!props.isDetail && (
        <Button onClick={deleteContactPerson} size={'sm'}>
          <FontAwesomeIcon icon={'trash'} />
        </Button>
      )}
      <hr />
    </div>
  );
};

CrmContactPersons.defaultProps = {
  contactPerson: {
    name: '',
    phone: '',
    mobile: '',
    email: ''
  }
};

CrmContactPersons.propTypes = {
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  contactPerson: PropTypes.object,
  contactPersonIndex: PropTypes.number,
  isDetail: PropTypes.bool
};

export default CrmContactPersons;

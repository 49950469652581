import React from 'react';

const Landing = () => {
  return (
    <>
      <h2>Hier folgt die Landingpage</h2>
    </>
  );
};

export default Landing;

import React from 'react';
import PropTypes from 'prop-types';
import CollapseElem from '../../../custom/CollapseElem';
import { Button } from 'react-bootstrap';
import CrmContactPerson from './CrmContactPerson';

const CrmContactPersons = props => {
  const addContactPerson = () => {
    let contactPersons = [...props.contactPersons];
    contactPersons.push({
      name: '',
      phone: '',
      mobile: '',
      email: ''
    });

    props.handleChange(contactPersons);
  };

  const handleChange = (contactPerson, index) => {
    let contactPersons = [...props.contactPersons];
    contactPersons[index] = contactPerson;

    props.handleChange(contactPersons);
  };

  const handleDelete = index => {
    let contactPersons = [...props.contactPersons];
    contactPersons.splice(index, 1);

    props.handleChange(contactPersons);
  };

  return (
    <div>
      <CollapseElem title="Ansprechpartner">
        {!props.isDetail && (
          <Button onClick={addContactPerson}>Kontaktperson hinzufügen</Button>
        )}

        {props.contactPersons.map((contactPerson, index) => (
          <CrmContactPerson
            key={index}
            contactPerson={contactPerson}
            contactPersonIndex={index}
            handleChange={handleChange}
            handleDelete={handleDelete}
            isDetail={props.isDetail}
          />
        ))}
      </CollapseElem>
      <hr />
    </div>
  );
};

CrmContactPersons.defaultProps = {
  contactPersons: []
};

CrmContactPersons.propTypes = {
  handleChange: PropTypes.func,
  contactPersons: PropTypes.array,
  isDetail: PropTypes.bool
};

export default CrmContactPersons;

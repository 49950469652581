import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { AuthProviderContext } from 'context/Context';

const ProtectedRoute = props => {
  const { element } = props;
  const { isAuthenticated, user } = useContext(AuthProviderContext);

  if (!isAuthenticated) {
    return <Navigate to="/authentication" />;
  }

  if (isAuthenticated && !user.isActive) {
    return <Navigate to="/locked" />;
  }

  if (isAuthenticated && !user.isCompleted) {
    return <Navigate to="/setup" />;
  }

  return <>{isAuthenticated ? element : <Navigate to="/authenticate" />}</>;
};

ProtectedRoute.propTypes = {
  path: PropTypes.string,
  element: PropTypes.node.isRequired
};

export default ProtectedRoute;

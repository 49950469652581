import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import CardLogin from 'components/authentication/card/Login';
import AppContext from 'context/Context';
import Cds from '../pages/Cds';
import PasswordManagement from '../pages/PasswordManagement';
import { useAuth0 } from '@auth0/auth0-react';
import LoginCallback from '../components/authentication/LoginCallback';
import SetupLayout from './SetupLayout';
import Setup from '../components/pages/setup/Setup';
import Dashboard from '../components/dashboards/default';
import Locked from '../components/pages/setup/Locked';
import Crm from '../pages/Crm';
import ProtectedRoute from '../authentication/ProtectedRoute';
import Settings from '../components/pages/user/settings/Settings';
import LaboratorySettings from '../pages/LaboratorySettings';
import Employee from '../pages/Employee';
import CdsTemplates from '../pages/CdsTemplates';

const Layout = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect();
  }, [isAuthenticated]);

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        <Route path="/landing" element={<Landing />} />

        <Route path="/authentication" element={<CardLogin />} />
        <Route path="/authentication/callback" element={<LoginCallback />} />

        <Route element={<SetupLayout />}>
          <Route path="/locked" element={<Locked />} />
          <Route path="/setup" element={<Setup />} />
        </Route>

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route
            path="/"
            element={<ProtectedRoute element={<Dashboard />} />}
          />

          <Route path="/cds" element={<ProtectedRoute element={<Cds />} />} />
          <Route path="/crm" element={<ProtectedRoute element={<Crm />} />} />
          <Route
            path="/users"
            element={<ProtectedRoute element={<Employee />} />}
          />
          <Route
            path="/settings"
            element={<ProtectedRoute element={<Settings />} />}
          />
          <Route
            path="/laboratory"
            element={<ProtectedRoute element={<LaboratorySettings />} />}
          />
          <Route
            path="/cds-templates"
            element={<ProtectedRoute element={<CdsTemplates />} />}
          />

          <Route
            path="/password-management"
            element={<ProtectedRoute element={<PasswordManagement />} />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CollapseElem = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <h5 className="clickable" onClick={() => setIsOpen(!isOpen)}>
        {props.title}{' '}
        <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
      </h5>
      {isOpen && <>{props.children}</>}
    </div>
  );
};

CollapseElem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default CollapseElem;

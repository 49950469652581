import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import HelpButton from '../components/custom/HelpButton';
import AddEntryButton from '../components/custom/AddEntryButton';
import ActionModal from '../components/custom/ActionModal';
import DeleteModal from '../components/custom/DeleteModal';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PasswordList from '../components/pages/password-management/PasswordList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthProviderContext, SearchContext } from '../context/Context';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import SortButton from '../components/custom/SortButton';
import PasswordGenerator from '../components/custom/PasswordGenerator';

class PasswordManagement extends React.Component {
  constructor(props) {
    super(props);
    this.actionModalRef = React.createRef();
    this.deleteEntryModalRef = React.createRef();

    this.state = {
      entries: [],
      entryToDelete: null,
      entryToUpdate: null,
      showPassword: false,
      entry: {
        name: '',
        type: 'website',
        login: '',
        password: '',
        url: '',
        softwareName: '',
        deviceName: ''
      },
      isLoading: false,
      sortDirection: 'asc',
      isDetail: false
    };
  }

  async componentDidMount() {
    await this.fetchEntries();
  }

  async fetchEntries() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    this.setState({ isLoading: true });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/accesses`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const entries = await response.data.data;
    this.setState({ entries: entries, isLoading: false });

    this.sortEntries();
  }

  resetState() {
    this.setState({
      entry: {
        name: '',
        type: 'website',
        login: '',
        password: '',
        url: '',
        softwareName: '',
        deviceName: '',
        notes: ''
      },
      showPassword: false,
      entryToUpdate: null,
      isDetail: false
    });
  }

  cancelModal() {
    this.resetState();
  }

  async submit() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    if (!this.state.entryToUpdate) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/accesses`,
          {
            ...this.state.entry
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await this.fetchEntries();
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/accesses/${this.state.entryToUpdate}`,
          {
            ...this.state.entry
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await this.fetchEntries();
        });
    }

    this.resetState();
  }

  openModal(entry = null, isDetail = false) {
    if (entry) {
      let editEntry = { ...entry };

      let id = editEntry._id;

      delete editEntry['_id'];

      this.setState({
        entry: editEntry,
        entryToUpdate: id
      });
    }

    if (isDetail) {
      this.setState({
        isDetail: true
      });
    }

    this.actionModalRef.current.toggleModal();
  }

  openDeleteEntryModal(id) {
    this.setState({ entryToDelete: id });

    this.deleteEntryModalRef.current.toggleModal();
  }

  async deleteEntry() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/accesses/${this.state.entryToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(() => {
        this.setState({
          entryToDelete: null
        });

        this.fetchEntries();
      });
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        [name]: value
      }
    }));
  }

  handlePasswordGeneratorChange(password) {
    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        password: password
      }
    }));
  }

  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  sortEntries(direction) {
    let dir = this.state.sortDirection;

    if (direction) {
      dir = direction;
    }

    if (dir === 'asc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      this.setState({
        entries: sorted
      });
    } else if (dir === 'desc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.name > b.name ? -1 : 1;
      });

      this.setState({
        entries: sorted
      });
    }
  }

  changeSortDirection(direction) {
    this.setState({
      sortDirection: direction
    });

    this.sortEntries(direction);
  }

  copyText(text) {
    navigator.clipboard.writeText(text).then(() => {});
  }

  render() {
    return (
      <div>
        <SearchContext.Consumer>
          {({ query }) => (
            <PasswordList
              accesses={this.state.entries}
              openDeleteModal={id => this.openDeleteEntryModal(id)}
              openUpdateModal={entry => this.openModal(entry)}
              openDetailModal={entry => this.openModal(entry, true)}
              isLoading={this.state.isLoading}
              searchQuery={query}
            />
          )}
        </SearchContext.Consumer>
        <SortButton handleClick={this.changeSortDirection.bind(this)} />
        <HelpButton />
        <AddEntryButton handleClick={() => this.openModal()} />
        <ActionModal
          ref={this.actionModalRef}
          title={
            this.state.entryToUpdate
              ? `Zugang ${this.state.entryToUpdate} ${
                  this.state.isDetail ? '' : 'editieren'
                }`
              : 'Neuen Zugang erstellen'
          }
          onSubmit={this.submit.bind(this)}
          onCancel={this.cancelModal.bind(this)}
          size={'md'}
          hasCloseButton={this.state.isDetail}
          hasSubmitButton={!this.state.isDetail}
          hasCancelButton={!this.state.isDetail}
        >
          {!this.state.isDetail ? (
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                required
                name="name"
                value={this.state.entry.name}
                onChange={this.handleChange.bind(this)}
                disabled={this.state.isDetail}
              />
              <Form.Control.Feedback type="invalid">
                Bitte gebe einen Namen ein.
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  required
                  name="name"
                  value={this.state.entry.name}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                />
                <InputGroup.Text>
                  <FontAwesomeIcon
                    onClick={() => this.copyText(this.state.entry.name)}
                    icon="copy"
                  />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  Bitte gebe einen Namen ein.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Typ</Form.Label>
            <Form.Select
              name="type"
              value={this.state.entry.type}
              onChange={this.handleChange.bind(this)}
              disabled={this.state.isDetail}
            >
              <option value="website">Website</option>
              <option value="device">Gerät</option>
              <option value="software">Software</option>
            </Form.Select>
          </Form.Group>
          {this.state.entry.type === 'website' && (
            <>
              {!this.state.isDetail ? (
                <Form.Group className="mb-3">
                  <Form.Label>Url</Form.Label>
                  <Form.Control
                    type="text"
                    name="url"
                    value={this.state.entry.url}
                    onChange={this.handleChange.bind(this)}
                    disabled={this.state.isDetail}
                  />
                </Form.Group>
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>Url</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="url"
                      value={this.state.entry.url}
                      onChange={this.handleChange.bind(this)}
                      disabled={this.state.isDetail}
                    />
                    <InputGroup.Text>
                      <FontAwesomeIcon
                        onClick={() => this.copyText(this.state.entry.url)}
                        icon="copy"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              )}
            </>
          )}
          {this.state.entry.type === 'device' && (
            <>
              {!this.state.isDetail ? (
                <Form.Group className="mb-3">
                  <Form.Label>Gerätename</Form.Label>
                  <Form.Control
                    type="text"
                    name="deviceName"
                    value={this.state.entry.deviceName}
                    onChange={this.handleChange.bind(this)}
                    disabled={this.state.isDetail}
                  />
                </Form.Group>
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>Gerätename</Form.Label>
                  <Form.Control
                    type="text"
                    name="deviceName"
                    value={this.state.entry.deviceName}
                    onChange={this.handleChange.bind(this)}
                    disabled={this.state.isDetail}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      onClick={() => this.copyText(this.state.entry.deviceName)}
                      icon="copy"
                    />
                  </InputGroup.Text>
                </Form.Group>
              )}
            </>
          )}
          {this.state.entry.type === 'software' && (
            <>
              {!this.state.isDetail ? (
                <Form.Group className="mb-3">
                  <Form.Label>Softwarename</Form.Label>
                  <Form.Control
                    type="text"
                    name="softwareName"
                    value={this.state.entry.softwareName}
                    onChange={this.handleChange.bind(this)}
                    disabled={this.state.isDetail}
                  />
                </Form.Group>
              ) : (
                <Form.Group className="mb-3">
                  <Form.Label>Softwarename</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="softwareName"
                      value={this.state.entry.softwareName}
                      onChange={this.handleChange.bind(this)}
                      disabled={this.state.isDetail}
                    />
                    <InputGroup.Text>
                      <FontAwesomeIcon
                        onClick={() =>
                          this.copyText(this.state.entry.softwareName)
                        }
                        icon="copy"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              )}
            </>
          )}
          {!this.state.isDetail ? (
            <Form.Group className="mb-3">
              <Form.Label>Login Name</Form.Label>
              <Form.Control
                type="text"
                name="login"
                value={this.state.entry.login}
                onChange={this.handleChange.bind(this)}
                disabled={this.state.isDetail}
                required
              />
              <Form.Control.Feedback type="invalid">
                Bitte gebe einen Login Namen ein.
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Login Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="login"
                  value={this.state.entry.login}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                  required
                />
                <InputGroup.Text>
                  <FontAwesomeIcon
                    onClick={() => this.copyText(this.state.entry.login)}
                    icon="copy"
                  />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  Bitte gebe einen Login Namen ein.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Passwort</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                onChange={this.handleChange.bind(this)}
                autoComplete="nope"
                value={this.state.entry.password}
                disabled={this.state.isDetail}
                required
              />
              <InputGroup.Text>
                <FontAwesomeIcon
                  onClick={this.toggleShowPassword.bind(this)}
                  icon={this.state.showPassword ? 'eye-slash' : 'eye'}
                />
              </InputGroup.Text>
              {this.state.isDetail && (
                <InputGroup.Text>
                  <FontAwesomeIcon
                    onClick={() => this.copyText(this.state.entry.password)}
                    icon="copy"
                  />
                </InputGroup.Text>
              )}
              <Form.Control.Feedback type="invalid">
                Bitte gebe ein Passwort ein.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Notizen</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              rows={4}
              value={this.state.entry.notes}
              onChange={this.handleChange.bind(this)}
              disabled={this.state.isDetail}
            />
          </Form.Group>
          {!this.state.isDetail && (
            <PasswordGenerator
              name="passwordGen"
              value={this.state.entry.password}
              onChange={this.handlePasswordGeneratorChange.bind(this)}
              isSimple={true}
            />
          )}
        </ActionModal>
        <DeleteModal
          ref={this.deleteEntryModalRef}
          onDelete={() => this.deleteEntry()}
        />
      </div>
    );
  }
}

PasswordManagement.propTypes = {
  auth0: PropTypes.any
};

PasswordManagement.contextType = AuthProviderContext;

export default withAuth0(PasswordManagement);

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const DeleteModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [valid, setValid] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal() {
      setShow(!show);
    }
  }));

  const handleChange = e => {
    e.preventDefault();
    setDeleteText(e.target.value);

    if (e.target.value === 'Löschen') {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const cancel = () => {
    setShow(!show);
    setValid(false);
    setDeleteText('');
  };

  const submit = () => {
    setShow(!show);
    setValid(false);
    setDeleteText('');
    props.onDelete();
  };

  return (
    <Modal show={show} onHide={() => cancel()}>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            Wenn du dir sicher bist, dass du diesen Eintrag löschen möchtest,
            tippe in das Feld "<b>Löschen</b>" ein.
          </Form.Label>
          <Form.Control
            type="text"
            value={deleteText}
            onChange={handleChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => cancel()}>
          abbrechen
        </Button>
        <Button variant="primary" disabled={!valid} onClick={() => submit()}>
          Ja, löschen
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

DeleteModal.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func
};

export default DeleteModal;

import React, { useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import generic1 from '../../../assets/img/logo.png';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { AuthProviderContext } from '../../../context/Context';

const EmployeeItem = props => {
  const { user } = useContext(AuthProviderContext);

  const toggleDetailModal = () => {
    props.openDetailModal(props.employee);
  };

  return (
    <Col sm={12} md={6} lg={3} className="my-3">
      <Card
        style={{ width: '15rem' }}
        className="clickable mx-auto mx-md-0"
        onClick={() => toggleDetailModal()}
      >
        <Card.Img src={generic1} variant="top" className="p-3" />
        <Card.Body>
          <h5 className="text-center">
            {props.employee.firstName + ' ' + props.employee.lastName}
          </h5>
          <p className="text-center text-muted small">{props.employee._id}</p>
          <Row
            className="cds-action-row"
            onClick={prevent(
              () => (window.location = `mailto:${props.employee.email}`)
            )}
          >
            <Col className="small" xs={9}>
              {props.employee.email}
            </Col>
            <Col>
              <FontAwesomeIcon icon={faEnvelope} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button
                variant="primary"
                size="sm"
                disabled={user._id === props.employee._id}
                onClick={prevent(() => props.openUpdateModal(props.employee))}
              >
                Editieren
              </Button>
            </Col>
            <Col>
              <Button
                variant="secondary"
                size="sm"
                className="float-end"
                disabled={user._id === props.employee._id}
                onClick={prevent(() =>
                  props.openDeleteModal(props.employee._id)
                )}
              >
                <FontAwesomeIcon icon="trash" />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

EmployeeItem.propTypes = {
  employee: PropTypes.object,
  openDeleteModal: PropTypes.func,
  openUpdateModal: PropTypes.func,
  openDetailModal: PropTypes.func
};

export function prevent(fn, defaultOnly) {
  return (e, ...params) => {
    e && e.preventDefault();
    !defaultOnly && e && e.stopPropagation();
    fn(e, ...params);
  };
}

export default EmployeeItem;

import React from 'react';
import PropTypes from 'prop-types';

const NoResults = props => {
  return <h4 className="mt-5">{props.text}</h4>;
};

NoResults.defaultProps = {
  text: 'Es sind noch keine Einträge vorhanden.'
};

NoResults.propTypes = {
  text: PropTypes.string
};

export default NoResults;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default function AddEntryButton(prop) {
  let { handleClick } = prop;

  return (
    <div className="add-entry-button" onClick={handleClick}>
      <FontAwesomeIcon icon={faPlus} />
    </div>
  );
}

import React from 'react';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CdsItem from './CdsItem';
import NoResults from '../../../pages/others/NoResults';
import Loading from '../../../authentication/Loading';

const CdsList = props => {
  return (
    <Row>
      {props.isLoading ? (
        <Loading />
      ) : (
        <>
          {props.customers.length > 0 ? (
            <>
              {props.customers.map(customer => (
                <>
                  {customer.dataSpecifics.map((dataSpecific, j) => (
                    <CdsItem
                      key={j}
                      customer={customer}
                      dataSpecific={dataSpecific}
                      openDeleteModal={id => props.openDeleteModal(id)}
                      openUpdateModal={(customer, dataSpecifics) =>
                        props.openUpdateModal(customer, dataSpecifics)
                      }
                      openDetailModal={(customer, dataSpecifics) =>
                        props.openDetailModal(customer, dataSpecifics)
                      }
                    />
                  ))}
                </>
              ))}
            </>
          ) : (
            <NoResults />
          )}
        </>
      )}
    </Row>
  );
};

CdsList.propTypes = {
  customers: PropTypes.array,
  openDeleteModal: PropTypes.func,
  openUpdateModal: PropTypes.func,
  openDetailModal: PropTypes.func,
  isLoading: PropTypes.bool
};

export default CdsList;

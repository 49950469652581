import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const ActionModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  useImperativeHandle(ref, () => ({
    toggleModal() {
      setShow(!show);
    }
  }));

  const submit = event => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();

      setValidated(true);
      return;
    } else {
      setShow(!show);

      setTimeout(() => {
        props.onSubmit();
      }, 150);

      setValidated(false);
    }
  };

  const cancel = () => {
    setShow(!show);
    setValidated(false);

    setTimeout(() => {
      props.onCancel();
    }, 150);
  };

  const next = () => {
    props.onNext();
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(!show)}
      size={props.size}
      backdrop="static"
      keyboard={false}
      className="min-vh-100 min-vw-100"
      dialogClassName="min-vh-100 min-vw-100 m-0"
      contentClassName="min-vh-100 min-vw-100"
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={submit}>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          {props.hasCloseButton && (
            <Button variant="secondary" onClick={() => cancel()}>
              {props.closeButtonText}
            </Button>
          )}
          {props.hasCancelButton && (
            <Button variant="secondary" onClick={() => cancel()}>
              {props.cancelButtonText}
            </Button>
          )}
          {props.hasSubmitButton && (
            <Button variant="primary" type="submit">
              {props.submitButtonText}
            </Button>
          )}
          {props.hasNextButton && (
            <Button variant="primary" onClick={() => next()}>
              {props.nextButtonText}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

ActionModal.defaultProps = {
  size: 'xl',
  hasCancelButton: true,
  hasSubmitButton: true,
  hasCloseButton: false,
  hasNextButton: false,
  submitButtonText: 'Speichern',
  cancelButtonText: 'abbrechen',
  closeButtonText: 'schließen',
  nextButtonText: 'weiter'
};

ActionModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onNext: PropTypes.func,
  size: PropTypes.string,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  hasCancelButton: PropTypes.bool,
  hasSubmitButton: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  hasNextButton: PropTypes.bool
};

export default ActionModal;

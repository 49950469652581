import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { AuthProviderContext } from 'context/Context';
import Loading from './Loading';
import Error from '../pages/others/Error';
import { Col, Row } from 'react-bootstrap';
import Logo from '../components/common/Logo';
import Section from '../components/common/Section';

const AuthContextProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [user, setUser] = useState({});

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser
  };

  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    let token = null;

    try {
      token = await getAccessTokenSilently({
        audience: 'api-gateway'
      });

      axios
        .get(`${process.env.REACT_APP_API_URL}/sign-in`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
          setUser(res.data.user);
          setIsAuthenticated(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    } catch (err) {
      if (err.message === 'Login required') {
        loginWithRedirect();
        return;
      }
      setIsLoading(false);
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError ? (
        <Section className="py-0">
          <Row className="flex-center min-vh-100 py-6">
            <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
              <Logo />
              <Error />
            </Col>
          </Row>
        </Section>
      ) : (
        <>
          {isLoading ? (
            <div
              style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Loading />
            </div>
          ) : (
            <AuthProviderContext.Provider value={value}>
              {props.children}
            </AuthProviderContext.Provider>
          )}
        </>
      )}
    </>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContextProvider;

import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import generic1 from '../../../assets/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const CdsItem = props => {
  const getLocation = () => {
    let customer = props.customer;
    let dataSpecific = props.dataSpecific;
    let location = 'Alle Standorte';

    if (dataSpecific.locationId !== null) {
      let locationObject = customer.locations.find(
        location => location._id === dataSpecific.locationId
      );
      location = locationObject?.name;
    }

    return location;
  };

  const toggleDetailModal = () => {
    props.openDetailModal(props.customer, props.dataSpecific);
  };

  return (
    <Col sm={12} md={6} lg={3} className="my-3">
      <Card
        style={{ width: '15rem' }}
        className="clickable mx-auto mx-md-0"
        onClick={() => toggleDetailModal()}
      >
        <Card.Img src={generic1} variant="top" className="p-3" />
        <Card.Body>
          <h5 className="text-center">{props.customer.name}</h5>
          <p className="text-center text-muted small">
            {props.customer.customerNumber ?? props.dataSpecific._id} <br />
            {getLocation()}
          </p>
          {props.customer.phone && (
            <Row
              className="cds-action-row"
              onClick={prevent(
                () => (window.location = `tel:${props.customer.phone}`)
              )}
            >
              <Col className="small" xs={9}>
                {props.customer.phone}
              </Col>
              <Col xs={3}>
                <FontAwesomeIcon icon={faPhone} />
              </Col>
            </Row>
          )}
          {props.customer.email && (
            <Row
              className="cds-action-row"
              onClick={prevent(
                () => (window.location = `mailto:${props.customer.email}`)
              )}
            >
              <Col className="small" xs={9}>
                {props.customer.email}
              </Col>
              <Col xs={3}>
                <FontAwesomeIcon icon={faEnvelope} />
              </Col>
            </Row>
          )}
          {props.customer.website && (
            <Row className="cds-action-row">
              <Col className="small" xs={9}>
                {props.customer.website}
              </Col>
              <Col xs={3}>
                <FontAwesomeIcon icon={faAt} />
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col>
              <Button
                variant="primary"
                size="sm"
                onClick={prevent(() =>
                  props.openUpdateModal(props.customer, props.dataSpecific)
                )}
              >
                Verwalten
              </Button>
            </Col>
            <Col>
              <Button
                variant="secondary"
                size="sm"
                className="float-end"
                onClick={prevent(() =>
                  props.openDeleteModal(props.dataSpecific._id)
                )}
              >
                <FontAwesomeIcon icon="trash" />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

CdsItem.propTypes = {
  customer: PropTypes.object,
  dataSpecific: PropTypes.object,
  openDeleteModal: PropTypes.func,
  openUpdateModal: PropTypes.func,
  openDetailModal: PropTypes.func
};

export function prevent(fn, defaultOnly) {
  return (e, ...params) => {
    e && e.preventDefault();
    !defaultOnly && e && e.stopPropagation();
    fn(e, ...params);
  };
}

export default CdsItem;

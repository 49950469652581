import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAlphaDown,
  faSortAlphaUp
} from '@fortawesome/free-solid-svg-icons';

export default function SortButton(prop) {
  let { handleClick } = prop;
  let [dir, setDir] = useState('asc');

  const sort = () => {
    if (dir === 'asc') {
      setDir('desc');

      handleClick('desc');
    } else if (dir === 'desc') {
      setDir('asc');

      handleClick('asc');
    }
  };

  return (
    <div className="sort-button" onClick={sort}>
      <FontAwesomeIcon icon={dir === 'asc' ? faSortAlphaDown : faSortAlphaUp} />
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import axios from 'axios';
import { AuthProviderContext } from '../context/Context';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';

const LaboratorySettings = props => {
  const { user } = useContext(AuthProviderContext);

  const [validated, setValidated] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    address: '',
    zipcode: '',
    city: '',
    sections: [
      {
        name: 'test'
      }
    ]
  });
  const [sections, setSections] = useState([]);

  const [newSection, setNewSection] = useState('');

  useEffect(async () => {
    await fetchEntries();
  }, []);

  const fetchEntries = async () => {
    const { getAccessTokenSilently } = props.auth0;
    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    axios
      .get(`${process.env.REACT_APP_API_URL}/laboratory/${user.laboratoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        let sections = res.data.data.sections || [];
        setSections(sections);
        delete res.data.data.sections;

        setFormData(res.data.data);
      });
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSectionChange = e => {
    setNewSection(e.target.value);
  };

  const handleSubmit = async e => {
    event.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();

      setValidated(true);
      return;
    } else {
      const { getAccessTokenSilently } = props.auth0;

      const token = await getAccessTokenSilently({
        audience: 'api-gateway'
      });

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/laboratory/${user.laboratoryId}`,
          {
            ...formData,
            sections: sections
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await fetchEntries();
          setValidated(false);
        });
    }
  };

  const handleDeleteSection = index => {
    let newSections = [...sections];
    newSections.splice(index, 1);

    setSections(newSections);
  };

  const createSection = () => {
    let newSections = [...sections];

    newSections.push({
      name: newSection
    });

    setSections(newSections);

    setNewSection('');
  };

  return (
    <Row className="g-3">
      <Col md={12}>
        <Card>
          <FalconCardHeader title="Labor Einstellungen" />
          <Card.Body className="bg-light">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe einen Namen ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Telefonnummer</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Telefonnummer"
                  value={formData.phone}
                  name="phone"
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe eine Telefonnummer ein.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="address">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="address"
                  value={formData.address}
                  name="address"
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe eine Adresse ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={4} controlId="zipcode">
                  <Form.Label>Postleitzahl</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Postleitzahl"
                    value={formData.zipcode}
                    name="zipcode"
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Bitte gebe eine Postleitzahl ein.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg={8} controlId="city">
                  <Form.Label>Stadt</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Stadt"
                    value={formData.city}
                    name="city"
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Bitte gebe eine Stadt ein.
                  </Form.Control.Feedback>
                </Form.Group>
                <hr className="mt-5" />
                <h2>Abteilungen</h2>
                <Row className="mt-2">
                  <Col sm={6}>
                    <Form.Group as={Col} lg={8} controlId="city">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        value={newSection}
                        name="city"
                        onChange={handleSectionChange}
                      />
                    </Form.Group>
                    <Button
                      type="button"
                      variant="primary"
                      className="mt-2"
                      onClick={() => createSection()}
                    >
                      Hinzufügen
                    </Button>
                  </Col>
                  <Col sm={12} className="mt-3">
                    {sections.length > 0 && (
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Aktion</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sections.map((section, index) => (
                            <tr key={index}>
                              <td>{section.name}</td>
                              <td>
                                <Button
                                  variant="danger"
                                  onClick={() => handleDeleteSection(index)}
                                >
                                  Löschen
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </Col>
                </Row>
              </Row>
              <div className="text-end">
                <Button variant="primary" type="submit">
                  Speichern
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

LaboratorySettings.propTypes = {
  auth0: PropTypes.any
};

export default withAuth0(LaboratorySettings);

import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import CrmList from '../components/pages/crm/CrmList';
import HelpButton from '../components/custom/HelpButton';
import AddEntryButton from '../components/custom/AddEntryButton';
import ActionModal from '../components/custom/ActionModal';
import DeleteModal from '../components/custom/DeleteModal';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AuthProviderContext, SearchContext } from '../context/Context';
import SortButton from '../components/custom/SortButton';
import CrmContactPersons from '../components/pages/crm/contact-persons/CrmContactPersons';
import CrmDeliveryAddresses from '../components/pages/crm/delivery-addresses/CrmDeliveryAddresses';
import CrmLocations from '../components/pages/crm/locations/CrmLocations';

class Crm extends React.Component {
  constructor(props) {
    super(props);
    this.actionModalRef = React.createRef();
    this.deleteEntryModalRef = React.createRef();

    this.state = {
      entries: [],
      entryToDelete: null,
      entryToUpdate: null,
      entry: {
        customerNumber: '',
        name: '',
        phone: '',
        fax: '',
        email: '',
        address: '',
        zipcode: '',
        city: '',
        country: '',
        notes: '',
        deliveryAddresses: [],
        contactPersons: [],
        locations: []
      },
      isLoading: false,
      sortDirection: 'asc',
      isDetail: false
    };
  }

  async componentDidMount() {
    await this.fetchEntries();
  }

  async fetchEntries() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    this.setState({ isLoading: true });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customers`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const entries = await response.data.data;
    this.setState({ entries: entries, isLoading: false });

    this.sortEntries();
  }

  resetState() {
    this.setState({
      entry: {
        customerNumber: '',
        name: '',
        phone: '',
        fax: '',
        email: '',
        address: '',
        zipcode: '',
        city: '',
        country: '',
        notes: '',
        deliveryAddresses: [],
        contactPersons: [],
        locations: []
      },
      entryToUpdate: null,
      isDetail: false
    });
  }

  openModal(entry = null, isDetail = false) {
    if (entry) {
      let editEntry = { ...entry };

      let id = editEntry._id;

      delete editEntry['_id'];

      this.setState({
        entry: editEntry,
        entryToUpdate: id
      });
    }

    if (isDetail) {
      this.setState({
        isDetail: true
      });
    }

    this.actionModalRef.current.toggleModal();
  }

  cancelModal() {
    this.resetState();
  }

  async submit() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    if (!this.state.entryToUpdate) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customers`,
          {
            ...this.state.entry
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await this.fetchEntries();
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customers/${this.state.entryToUpdate}`,
          {
            ...this.state.entry
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await this.fetchEntries();
        });
    }

    this.resetState();
  }
  openDeleteEntryModal(id) {
    this.setState({ entryToDelete: id });

    this.deleteEntryModalRef.current.toggleModal();
  }

  async deleteEntry() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customers/${this.state.entryToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(() => {
        this.setState({
          entryToDelete: null
        });

        this.fetchEntries();
      });
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        [name]: value
      }
    }));
  }

  sortEntries(direction) {
    let dir = this.state.sortDirection;

    if (direction) {
      dir = direction;
    }

    if (dir === 'asc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      this.setState({
        entries: sorted
      });
    } else if (dir === 'desc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.name > b.name ? -1 : 1;
      });

      this.setState({
        entries: sorted
      });
    }
  }

  changeSortDirection(direction) {
    this.setState({
      sortDirection: direction
    });

    this.sortEntries(direction);
  }

  handleContactPersonsChange(contactPersons) {
    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        contactPersons: contactPersons
      }
    }));
  }

  handleDeliveryAddressesChange(deliveryAddresses) {
    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        deliveryAddresses: deliveryAddresses
      }
    }));
  }

  handleLocationsChange(locations) {
    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        locations: locations
      }
    }));
  }

  render() {
    return (
      <div>
        <SearchContext.Consumer>
          {({ query }) => (
            <CrmList
              customers={this.state.entries}
              openDeleteModal={id => this.openDeleteEntryModal(id)}
              openUpdateModal={entry => this.openModal(entry)}
              openDetailModal={entry => this.openModal(entry, true)}
              isLoading={this.state.isLoading}
              searchQuery={query}
            />
          )}
        </SearchContext.Consumer>
        <SortButton handleClick={this.changeSortDirection.bind(this)} />
        <HelpButton />
        <AddEntryButton handleClick={() => this.openModal()} />
        <ActionModal
          ref={this.actionModalRef}
          title={
            this.state.entryToUpdate
              ? `Kunde ${
                  this.state.entry.customerNumber ?? this.state.entryToUpdate
                } ${this.state.isDetail ? '' : 'editieren'}`
              : 'Neuen Kunden erstellen'
          }
          onSubmit={this.submit.bind(this)}
          onCancel={this.cancelModal.bind(this)}
          size={'xl'}
          hasCloseButton={this.state.isDetail}
          hasSubmitButton={!this.state.isDetail}
          hasCancelButton={!this.state.isDetail}
        >
          <Row>
            <Col sm={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Kundennummer</Form.Label>
                <Form.Control
                  type="text"
                  name="customerNumber"
                  value={this.state.entry.customerNumber}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe eine Kundennummer ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={this.state.entry.name}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe einen Namen ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Telefonnr.</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={this.state.entry.phone}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe eine Telefonnummer ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Fax</Form.Label>
                <Form.Control
                  type="text"
                  name="fax"
                  value={this.state.entry.fax}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={this.state.entry.email}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Bitte gebe eine E-Mail Adresse ein.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={this.state.entry.address}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                />
              </Form.Group>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Postleitzahl</Form.Label>
                    <Form.Control
                      type="text"
                      name="zipcode"
                      value={this.state.entry.zipcode}
                      onChange={this.handleChange.bind(this)}
                      disabled={this.state.isDetail}
                    />
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group className="mb-3">
                    <Form.Label>Stadt</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={this.state.entry.city}
                      onChange={this.handleChange.bind(this)}
                      disabled={this.state.isDetail}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <CrmDeliveryAddresses
                deliveryAddresses={this.state.entry.deliveryAddresses}
                handleChange={this.handleDeliveryAddressesChange.bind(this)}
                isDetail={this.state.isDetail}
              />
              <CrmContactPersons
                contactPersons={this.state.entry.contactPersons}
                handleChange={this.handleContactPersonsChange.bind(this)}
                isDetail={this.state.isDetail}
              />
              <CrmLocations
                locations={this.state.entry.locations}
                handleChange={this.handleLocationsChange.bind(this)}
                isDetail={this.state.isDetail}
              />

              <Form.Group className="mb-3">
                <Form.Label>Notizen</Form.Label>
                <Form.Control
                  as="textarea"
                  name="notes"
                  rows={4}
                  value={this.state.entry.notes}
                  onChange={this.handleChange.bind(this)}
                  disabled={this.state.isDetail}
                />
              </Form.Group>
            </Col>
          </Row>
        </ActionModal>
        <DeleteModal
          ref={this.deleteEntryModalRef}
          onDelete={() => this.deleteEntry()}
        />
      </div>
    );
  }
}

Crm.propTypes = {
  auth0: PropTypes.any
};

Crm.contextType = AuthProviderContext;

export default withAuth0(Crm);

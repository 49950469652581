import React from 'react';
import { Card } from 'react-bootstrap';

const Error = () => (
  <Card className="text-center h-100">
    <Card.Body className="p-5">
      <p className="lead mt-2 text-800 font-sans-serif fw-semi-bold">
        Es ist ein Fehler unterlaufen.
      </p>
      <hr />
      <p>
        Versuchen Sie die Seite neuzuladen. Sollte dieses Problem weiterhin
        bestehen,
        <a href="mailto:info@fixbyte.it" className="ms-1">
          kontaktieren Sie uns
        </a>
        .
      </p>
    </Card.Body>
  </Card>
);

export default Error;

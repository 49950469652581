import { Card } from 'react-bootstrap';
import Flex from '../../common/Flex';
import React, { useContext } from 'react';
import { AuthProviderContext } from '../../../context/Context';
import { Navigate } from 'react-router-dom';

const Locked = () => {
  const { user } = useContext(AuthProviderContext);

  if (user.isActive) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <h6 className="mb-0 mt-2">Noch nicht freigeschaltet.</h6>
        </Card.Header>

        <Card.Body as={Flex} alignItems="end" justifyContent="start">
          Es scheint als ist dein Account noch nicht freigeschaltet.
          <br />
          Wir informieren dich, sobald dein Account freigeschaltet wurde.
        </Card.Body>
      </Card>
    </>
  );
};

export default Locked;

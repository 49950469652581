import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import WizardInput from '../../../../wizard/WizardInput';

const LaboratoryForm = ({ register, errors }) => {
  return (
    <>
      <WizardInput
        type="text"
        label="Name des Labors *"
        name="name"
        group="laboratory"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('laboratory.name', {
            required: 'Bitte gebe den Namen des Labors ein.'
          })
        }}
      />
      <WizardInput
        type="text"
        label="Straße & Hausnummer *"
        name="address"
        group="laboratory"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('laboratory.address', {
            required: 'Bitte gebe die Adresse des Labors ein.'
          })
        }}
      />
      <Row>
        <Col sm={12} md={3}>
          <WizardInput
            type="text"
            label="Postleitzahl *"
            name="zipcode"
            group="laboratory"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('laboratory.zipcode', {
                required: 'Bitte gebe die Postleitzahl des Labors ein.'
              })
            }}
          />
        </Col>
        <Col sm={12} md={9}>
          <WizardInput
            type="text"
            label="Stadt *"
            name="city"
            group="laboratory"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('laboratory.city', {
                required: 'Bitte gebe die Stadt des Labors ein.'
              })
            }}
          />
        </Col>
      </Row>

      <WizardInput
        type="number"
        label="Telefonnr. *"
        name="phone"
        group="laboratory"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('laboratory.phone', {
            required: 'Bitte gebe die Telefonnr. des Labors ein.'
          })
        }}
      />

      <WizardInput
        type="select"
        label="Anzahl der Mitarbeiter *"
        name="employeesCount"
        group="laboratory"
        placeholder="Anzahl der Mitarbeiter"
        errors={errors}
        options={[
          '1-5',
          '6-10',
          '11-15',
          '16-25',
          '26-50',
          '51-100',
          '101-200',
          '200+'
        ]}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('laboratory.employeesCount', {
            required: 'Bitte gebe die Anzahl deiner Mitarbeiter ein.'
          })
        }}
      />

      <WizardInput
        type="select"
        label="Erfahren von"
        name="experiencedFrom"
        group="laboratory"
        placeholder="Erfahren von"
        errors={errors}
        options={[
          'Google',
          'Instagram',
          'Facebook',
          'Werbung (sonstiges)',
          'persönliche Empfehlung'
        ]}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('laboratory.experiencedFrom')
        }}
      />
    </>
  );
};

LaboratoryForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default LaboratoryForm;

import React from 'react';
import PropTypes from 'prop-types';
import CollapseElem from '../../../custom/CollapseElem';
import { Button } from 'react-bootstrap';
import CrmDeliveryAddress from './CrmDeliveryAddress';

const CrmDeliveryAddresses = props => {
  const addDeliveryAddress = () => {
    let deliveryAddresses = [...props.deliveryAddresses];
    deliveryAddresses.push({
      address: '',
      zipcode: '',
      city: '',
      country: ''
    });

    props.handleChange(deliveryAddresses);
  };

  const handleChange = (deliveryAddress, index) => {
    let deliveryAddresses = [...props.deliveryAddresses];
    deliveryAddresses[index] = deliveryAddress;

    props.handleChange(deliveryAddresses);
  };

  const handleDelete = index => {
    let deliveryAddresses = [...props.deliveryAddresses];
    deliveryAddresses.splice(index, 1);

    props.handleChange(deliveryAddresses);
  };

  return (
    <div>
      <CollapseElem title="Lieferadressen">
        {!props.isDetail && (
          <Button onClick={addDeliveryAddress}>Lieferadresse hinzufügen</Button>
        )}

        {props.deliveryAddresses.map((contactPerson, index) => (
          <CrmDeliveryAddress
            key={index}
            deliveryAddress={contactPerson}
            deliveryAddressIndex={index}
            handleChange={handleChange}
            handleDelete={handleDelete}
            isDetail={props.isDetail}
          />
        ))}
      </CollapseElem>
      <hr />
    </div>
  );
};

CrmDeliveryAddresses.defaultProps = {
  deliveryAddresses: []
};

CrmDeliveryAddresses.propTypes = {
  handleChange: PropTypes.func,
  deliveryAddresses: PropTypes.array,
  isDetail: PropTypes.bool
};

export default CrmDeliveryAddresses;

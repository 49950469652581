import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CrmDeliveryAddresses from '../delivery-addresses/CrmDeliveryAddresses';
import CrmContactPersons from '../contact-persons/CrmContactPersons';

const CrmLocation = props => {
  const [location, setLocation] = React.useState({});

  useEffect(() => {
    setLocation(props.location);
  }, [props.location]);

  const handleChange = e => {
    const locationCopy = { ...location };
    locationCopy[e.target.name] = e.target.value;
    setLocation(locationCopy);

    props.handleChange(locationCopy, props.locationIndex);
  };

  const handleDeliveryAddressChange = deliveryAddresses => {
    const locationCopy = { ...location };
    locationCopy.deliveryAddresses = deliveryAddresses;
    setLocation(locationCopy);

    props.handleChange(locationCopy, props.locationIndex);
  };

  const handleContactPersonChange = contactPersons => {
    const locationCopy = { ...location };
    locationCopy.contactPersons = contactPersons;
    setLocation(locationCopy);

    props.handleChange(locationCopy, props.locationIndex);
  };

  const deleteLocation = () => {
    props.handleDelete(props.locationIndex);
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={location.name}
          onChange={handleChange}
          disabled={props.isDetail}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte gebe einen Namen ein.
        </Form.Control.Feedback>
      </Form.Group>
      <CrmDeliveryAddresses
        deliveryAddresses={location.deliveryAddresses}
        handleChange={handleDeliveryAddressChange}
        isDetail={props.isDetail}
      ></CrmDeliveryAddresses>
      <CrmContactPersons
        contactPersons={location.contactPersons}
        handleChange={handleContactPersonChange}
        isDetail={props.isDetail}
      ></CrmContactPersons>
      {!props.isDetail && (
        <Button onClick={deleteLocation} size={'sm'}>
          <FontAwesomeIcon icon={'trash'} />
        </Button>
      )}
      <hr />
    </div>
  );
};

CrmLocation.defaultProps = {
  location: {
    name: '',
    deliveryAddresses: [],
    contactPersons: []
  }
};

CrmLocation.propTypes = {
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  location: PropTypes.object,
  locationIndex: PropTypes.number,
  isDetail: PropTypes.bool
};

export default CrmLocation;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SetupWizardContext } from 'context/Context';

const SetupWizardProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [step, setStep] = useState(1);

  const value = { userData, setUserData, step, setStep };
  return (
    <SetupWizardContext.Provider value={value}>
      {children}
    </SetupWizardContext.Provider>
  );
};

SetupWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SetupWizardProvider;

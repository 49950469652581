import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { SearchContext } from '../../context/Context';

const Search = () => {
  const searchContext = useContext(SearchContext);

  return (
    <div className="search-box">
      <Form.Control
        type="search"
        placeholder="Suchen..."
        aria-label="Suchen"
        className="rounded-pill search-input"
        value={searchContext.query}
        onChange={e => searchContext.searchHandler(e.target.value)}
      />
    </div>
  );
};

export default Search;

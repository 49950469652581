import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CrmDeliveryAddress = props => {
  const [deliveryAddress, setDeliveryAddress] = React.useState({});

  useEffect(() => {
    setDeliveryAddress(props.deliveryAddress);
  }, [props.deliveryAddress]);

  const handleChange = e => {
    const deliveryAddressCopy = { ...deliveryAddress };
    deliveryAddressCopy[e.target.name] = e.target.value;
    setDeliveryAddress(deliveryAddressCopy);

    props.handleChange(deliveryAddressCopy, props.deliveryAddressIndex);
  };

  const deleteDeliveryAddress = () => {
    props.handleDelete(props.deliveryAddressIndex);
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Adresse</Form.Label>
        <Form.Control
          type="text"
          name="address"
          value={deliveryAddress.address}
          onChange={handleChange}
          disabled={props.isDetail}
          required
        />
        <Form.Control.Feedback type="invalid">
          Bitte gebe eine Adresse ein.
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Postleitzahl</Form.Label>
            <Form.Control
              type="text"
              name="zipcode"
              value={deliveryAddress.zipcode}
              onChange={handleChange}
              disabled={props.isDetail}
              required
            />
            <Form.Control.Feedback type="invalid">
              Bitte gebe eine PLZ ein.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={8}>
          <Form.Group className="mb-3">
            <Form.Label>Stadt</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={deliveryAddress.city}
              onChange={handleChange}
              disabled={props.isDetail}
              required
            />
            <Form.Control.Feedback type="invalid">
              Bitte gebe eine Stadt ein.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {!props.isDetail && (
        <Button onClick={deleteDeliveryAddress} size={'sm'}>
          <FontAwesomeIcon icon={'trash'} />
        </Button>
      )}
      <hr />
    </div>
  );
};

CrmDeliveryAddress.defaultProps = {
  deliveryAddress: {
    address: '',
    zipcode: '',
    city: '',
    country: ''
  }
};

CrmDeliveryAddress.propTypes = {
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  deliveryAddress: PropTypes.object,
  deliveryAddressIndex: PropTypes.number,
  isDetail: PropTypes.bool
};

export default CrmDeliveryAddress;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import WizardInput from '../../../../wizard/WizardInput';

const PersonalForm = ({ register, errors, setValue }) => {
  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <WizardInput
            type="text"
            label="Vorname *"
            name="firstName"
            group="user"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('user.firstName', {
                required: 'Bitte gebe deinen Vornamen ein.'
              })
            }}
          />
        </Col>
        <Col sm={12} md={6}>
          <WizardInput
            type="text"
            label="Nachname *"
            name="lastName"
            group="user"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('user.lastName', {
                required: 'Bitte gebe deinen Nachnamen ein.'
              })
            }}
          />
        </Col>
      </Row>
      <WizardInput
        type="text"
        label="Straße & Hausnummer *"
        name="address"
        group="user"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('user.address', {
            required: 'Bitte gebe deine Straße & Hausnummer ein.'
          })
        }}
      />
      <Row>
        <Col sm={12} md={3}>
          <WizardInput
            type="text"
            label="Postleitzahl *"
            name="zipcode"
            group="user"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('user.zipcode', {
                required: 'Bitte gebe deine Postleitzahl ein.'
              })
            }}
          />
        </Col>
        <Col sm={12} md={9}>
          <WizardInput
            type="text"
            label="Stadt *"
            name="city"
            group="user"
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('user.city', {
                required: 'Bitte gebe dein Wohnort ein.'
              })
            }}
          />
        </Col>
      </Row>

      <WizardInput
        type="date"
        label="Geburtstag *"
        name="birthday"
        group="user"
        errors={errors}
        setValue={setValue}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          placeholder: 'Geburtstag',
          ...register('user.birthday', {
            required: 'Bitte gebe dein Geburtstag ein.'
          })
        }}
      />
    </>
  );
};

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default PersonalForm;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import ExperiencesSettings from './ExperiencesSettings';
import EducationSettings from './EducationSettings';

const Settings = () => {
  return (
    <>
      <Row className="g-3">
        <Col md={12}>
          <ProfileSettings />
          <ExperiencesSettings />
          <EducationSettings />
        </Col>
      </Row>
    </>
  );
};

export default Settings;

import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import EmployeeItem from './EmployeeItem';
import NoResults from '../../../pages/others/NoResults';
import Loading from '../../../authentication/Loading';

const EmployeeList = props => {
  const [entries, setEntries] = useState(props.employees);

  useEffect(() => {
    filterEntries();
  }, [props.employees]);

  useEffect(() => {
    filterEntries();
  }, [props.searchQuery]);

  const filterEntries = () => {
    if (props.searchQuery !== '') {
      const filteredEntries = props.employees.filter(entry => {
        if (
          (
            entry.firstName.toLowerCase() +
            ' ' +
            entry.lastName.toLowerCase()
          ).includes(props.searchQuery.toLowerCase()) ||
          entry?.email?.toLowerCase().includes(props.searchQuery.toLowerCase())
        ) {
          return entry;
        }
      });
      setEntries(filteredEntries);
    } else {
      setEntries(props.employees);
    }
  };

  return (
    <Row>
      {props.isLoading ? (
        <Loading />
      ) : (
        <>
          {entries.length > 0 ? (
            <>
              {entries.map((employee, i) => (
                <EmployeeItem
                  key={i}
                  employee={employee}
                  openDeleteModal={id => props.openDeleteModal(id)}
                  openUpdateModal={employee => props.openUpdateModal(employee)}
                  openDetailModal={employee => props.openDetailModal(employee)}
                />
              ))}
            </>
          ) : (
            <>
              {props.searchQuery === '' ? (
                <NoResults text="Es sind noch keine Mitarbeiter vorhanden." />
              ) : (
                <NoResults text="Es wurden keine Mitarbeiter gefunden." />
              )}
            </>
          )}
        </>
      )}
    </Row>
  );
};

EmployeeList.defaultProps = {
  searchQuery: ''
};

EmployeeList.propTypes = {
  employees: PropTypes.array,
  openDeleteModal: PropTypes.func,
  openUpdateModal: PropTypes.func,
  openDetailModal: PropTypes.func,
  isLoading: PropTypes.bool,
  isSearch: PropTypes.bool,
  searchQuery: PropTypes.string
};

export default EmployeeList;

import React from 'react';
import PropTypes from 'prop-types';
import CollapseElem from '../../../custom/CollapseElem';
import { Button } from 'react-bootstrap';
import CrmLocation from './CrmLocation';

const CrmLocations = props => {
  const addLocation = () => {
    let locations = [...props.locations];
    locations.push({
      name: '',
      deliveryAddresses: [],
      contactPersons: []
    });

    props.handleChange(locations);
  };

  const handleChange = (location, index) => {
    let locations = [...props.locations];
    locations[index] = location;

    props.handleChange(locations);
  };

  const handleDelete = index => {
    let locations = [...props.locations];
    locations.splice(index, 1);

    props.handleChange(locations);
  };

  return (
    <div>
      <CollapseElem title="Standorte">
        {!props.isDetail && (
          <Button onClick={addLocation}>Standort hinzufügen</Button>
        )}

        {props.locations.map((location, index) => (
          <CrmLocation
            key={index}
            location={location}
            locationIndex={index}
            handleChange={handleChange}
            handleDelete={handleDelete}
            isDetail={props.isDetail}
          />
        ))}
      </CollapseElem>
      <hr />
    </div>
  );
};

CrmLocations.defaultProps = {
  locations: []
};

CrmLocations.propTypes = {
  handleChange: PropTypes.func,
  locations: PropTypes.array,
  isDetail: PropTypes.bool
};

export default CrmLocations;

import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import CdsTemplateCardStandard from '../components/pages/cds-templates/CdsTemplateCardStandard';

const CdsTemplates = () => {
  return (
    <div>
      <Tabs defaultActiveKey="standard" id="uncontrolled-tab-example">
        <Tab eventKey="standard" title="Standard">
          <CdsTemplateCardStandard />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CdsTemplates;

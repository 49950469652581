import React, { useContext } from 'react';
import SetupWizard from './wizard/SetupWizard';
import SetupWizardProvider from './wizard/SetupWizardProvider';
import { AuthProviderContext } from '../../../context/Context';
import { Navigate } from 'react-router-dom';

const Setup = () => {
  const { user } = useContext(AuthProviderContext);

  if (!user.isActive) {
    return <Navigate to="/locked" />;
  }

  if (user.isCompleted) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <SetupWizardProvider>
        <SetupWizard validation={true} />
      </SetupWizardProvider>
    </>
  );
};

export default Setup;

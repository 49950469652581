import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import HelpButton from '../components/custom/HelpButton';
import AddEntryButton from '../components/custom/AddEntryButton';
import ActionModal from '../components/custom/ActionModal';
import DeleteModal from '../components/custom/DeleteModal';
import {
  Accordion,
  Button,
  Col,
  Form,
  Nav,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AuthProviderContext, SearchContext } from '../context/Context';
import SortButton from '../components/custom/SortButton';
import CdsList from '../components/pages/cds/CdsList';
import TinymceEditor from '../components/common/TinymceEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class Cds extends React.Component {
  constructor(props) {
    super(props);
    this.actionModalRef = React.createRef();
    this.deleteEntryModalRef = React.createRef();

    this.state = {
      entries: [],
      entryToDelete: null,
      entryToUpdate: null,
      entry: {
        customerNumber: '',
        name: '',
        email: '',
        fax: '',
        phone: '',
        customerId: '',
        locationId: '',
        sections: []
      },
      newField: {
        name: '',
        type: 'text',
        content: ''
      },
      templateId: '',
      availableLocations: [],
      templates: [],
      isLoading: false,
      sortDirection: 'asc',
      isDetail: false,
      step: 1
    };
  }

  async componentDidMount() {
    await this.fetchEntries();

    let templates = await this.getTemplates();

    this.setState({
      templates: templates
    });
  }

  async fetchEntries() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    this.setState({ isLoading: true });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customer-data-specifics`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const entries = await response.data.data;
    this.setState({ entries: entries, isLoading: false });

    this.sortEntries();

    this.setCustomers();
  }

  resetState() {
    this.setState({
      entry: {
        name: '',
        email: '',
        fax: '',
        phone: '',
        customerId: '',
        locationId: null,
        sections: []
      },
      newField: {
        name: '',
        type: 'text',
        content: ''
      },
      availableLocations: [],
      entryToUpdate: null,
      isDetail: false,
      step: 1
    });

    this.setCustomers();
  }

  async openModal(entry = null, dataSpecifics = null, isDetail = false) {
    if (entry) {
      let editEntry = { ...entry };
      let dataSpecificsEntry = { ...dataSpecifics };

      let id = dataSpecificsEntry._id;

      delete dataSpecificsEntry['_id'];

      let newEntry = {
        customerNumber: editEntry.customerNumber,
        name: editEntry.name,
        email: editEntry.email,
        fax: editEntry.fax,
        phone: editEntry.phone,
        customerId: dataSpecificsEntry.customerId,
        locationId: dataSpecificsEntry.locationId,
        sections: dataSpecificsEntry.sections
      };

      this.setState({
        step: 2,
        entry: newEntry,
        entryToUpdate: id
      });

      if (newEntry.sections.length === 0) {
        await this.setAvailableSections();
      }
    }

    if (isDetail) {
      this.setState({
        isDetail: true
      });
    }

    this.actionModalRef.current.toggleModal();
  }

  cancelModal() {
    this.resetState();
  }

  async submit() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    if (!this.state.entryToUpdate) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customer-data-specifics`,
          {
            ...this.state.entry
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await this.fetchEntries();
        });
    } else {
      let updateEntry = { ...this.state.entry };
      delete updateEntry.customerNumber;

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customer-data-specifics/${this.state.entryToUpdate}`,
          {
            ...updateEntry
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(async () => {
          await this.fetchEntries();
        });
    }

    this.resetState();
  }
  openDeleteEntryModal(id) {
    this.setState({ entryToDelete: id });

    this.deleteEntryModalRef.current.toggleModal();
  }

  async deleteEntry() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    await axios
      .delete(
        `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customer-data-specifics/${this.state.entryToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(() => {
        this.setState({
          entryToDelete: null
        });

        this.fetchEntries();
      });
  }

  handleChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        [name]: value
      }
    }));
  }

  handleFieldChange(event) {
    let value = event.target.value;
    let name = event.target.name;

    this.setState(prevState => ({
      newField: {
        ...prevState.newField,
        [name]: value
      }
    }));
  }

  sortEntries(direction) {
    let dir = this.state.sortDirection;

    if (direction) {
      dir = direction;
    }

    if (dir === 'asc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      this.setState({
        entries: sorted
      });
    } else if (dir === 'desc') {
      const sorted = [...this.state.entries].sort((a, b) => {
        return a.name > b.name ? -1 : 1;
      });

      this.setState({
        entries: sorted
      });
    }
  }

  changeSortDirection(direction) {
    this.setState({
      sortDirection: direction
    });

    this.sortEntries(direction);
  }

  handleCustomerChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    let availableLocations = [];

    if (value !== 'new') {
      availableLocations = this.getAvailableLocations(value);
    } else {
      value = 'new';
    }

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        [name]: value
      },
      availableLocations: availableLocations
    }));
  }

  getCustomerDataSpecifics() {
    return this.state.entries.filter(entry => {
      return entry.dataSpecifics.length > 0;
    });
  }

  getAvailableCustomers() {
    let customers = this.state.entries.filter(entry => {
      let locationsCount = entry.locations.length + 1;

      return entry.dataSpecifics.length < locationsCount;
    });

    return customers;
  }

  setCustomers() {
    let customers = this.getAvailableCustomers();

    if (customers.length > 0 && this.state.availableLocations.length === 0) {
      let selectedCustomer = customers[0];
      let availableLocations = [];
      let locationId = '';
      let customerId = 'new';

      if (this.state.entry.customerId !== 'new') {
        customerId = selectedCustomer._id;
        availableLocations = this.getAvailableLocations(selectedCustomer._id);
        locationId = availableLocations[0]._id;
      }

      this.setState(prevState => ({
        entry: {
          ...prevState.entry,
          customerId: customerId,
          locationId: locationId
        },
        availableLocations: availableLocations
      }));
    } else {
      this.setState(prevState => ({
        entry: {
          ...prevState.entry,
          customerId: 'new'
        }
      }));
    }
  }

  getLocationsCount(customerId) {
    return (
      this.state.entries.find(entry => {
        return entry._id === customerId;
      })?.locations?.length ?? 0
    );
  }

  getAvailableLocations(customerId) {
    let customer = this.state.entries.find(entry => {
      return entry._id === customerId;
    });

    let availableLocations = [];

    customer.locations.forEach(location => {
      let dataSpecific = customer.dataSpecifics.find(dataSpecific => {
        return dataSpecific.locationId === location._id;
      });

      if (!dataSpecific) {
        availableLocations.push(location);
      }
    });

    let hasGlobalDataSpecific = customer.dataSpecifics.find(dataSpecific => {
      return dataSpecific.locationId === null;
    });

    if (!hasGlobalDataSpecific) {
      availableLocations.unshift({
        _id: null,
        name: 'Allgemein'
      });
    }

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        locationId: availableLocations[0]._id
      }
    }));

    return availableLocations;
  }

  async next() {
    let step = this.state.step + 1;
    if (this.state.step === 1 && this.state.entry.customerId === 'new') {
      step = 3;
    } else if (this.state.step === 3) {
      step = 2;
    }

    let customer = this.state.entries.find(entry => {
      return entry._id === this.state.entry.customerId;
    });

    if (this.state.step === 3) {
      this.setState({
        step: step
      });
    } else {
      this.setState(prevState => ({
        entry: {
          ...prevState.entry,
          name: customer?.name ?? '',
          email: customer?.email ?? '',
          fax: customer?.fax ?? '',
          phone: customer?.phone ?? ''
        },
        step: step
      }));

      await this.setAvailableSections();
    }
  }

  async setAvailableSections() {
    let availableSections = await this.getAvailableSections();
    let sectionsArr = [];

    let standardTemplate = this.state.templates.find(template => {
      return template.name === 'Standard';
    });

    if (standardTemplate && this.state.templateId === '') {
      this.setState({
        templateId: standardTemplate._id
      });
    }

    availableSections.forEach(section => {
      if (this.state.templateId !== '') {
        let template = this.state.templates.find(template => {
          return template._id === this.state.templateId;
        });

        let tSection = template.sections.find(templateSection => {
          return templateSection.sectionId === section._id;
        });

        if (tSection) {
          sectionsArr.push({
            name: section.name,
            fields: tSection.fields
          });
        }
      } else {
        sectionsArr.push({
          name: section.name,
          fields: []
        });
      }
    });

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        sections: sectionsArr
      }
    }));
  }

  async getAvailableSections() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/sections`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.data;
  }

  addField(index) {
    let sections = [...this.state.entry.sections];

    sections[index].fields.push(this.state.newField);

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        sections: sections
      },
      newField: {
        name: '',
        type: 'text',
        content: ''
      }
    }));
  }

  removeField(index, fieldIndex) {
    let sections = [...this.state.entry.sections];

    sections[index].fields.splice(fieldIndex, 1);

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        sections: sections
      }
    }));
  }

  handleFieldValueChange(value, sectionIndex, fieldIndex) {
    let fields = [...this.state.entry.sections[sectionIndex].fields];
    fields[fieldIndex].content = value;

    this.setState(prevState => ({
      entry: {
        ...prevState.entry,
        sections: [...prevState.entry.sections]
      }
    }));
  }

  getModalTitle() {
    if (this.state.step === '3') {
      return 'Neuen Kunden anlegen';
    }

    if (this.state.entryToUpdate) {
      return `${this.state.entry.customerNumber ?? ''} ${
        this.state.entry.name
      } ${this.state.isDetail ? '' : 'bearbeiten'}`;
    }

    return 'Neuen CDS anlegen';
  }

  async getTemplates() {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: 'api-gateway'
    });

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/laboratory/${this.context.user.laboratoryId}/customer-data-specifics-template`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data.data;
  }

  handleTemplateChange(event) {
    let value = event.target.value;

    this.setState({
      templateId: value
    });
  }

  render() {
    return (
      <div>
        <SearchContext.Consumer>
          {({ query }) => (
            <CdsList
              customers={this.getCustomerDataSpecifics()}
              openDeleteModal={id => this.openDeleteEntryModal(id)}
              openUpdateModal={(entry, dataSpecifics) =>
                this.openModal(entry, dataSpecifics)
              }
              openDetailModal={(entry, dataSpecifics) =>
                this.openModal(entry, dataSpecifics, true)
              }
              isLoading={this.state.isLoading}
              searchQuery={query}
            />
          )}
        </SearchContext.Consumer>
        <SortButton handleClick={this.changeSortDirection.bind(this)} />
        <HelpButton />
        <AddEntryButton handleClick={() => this.openModal()} />
        <ActionModal
          ref={this.actionModalRef}
          title={this.getModalTitle()}
          onSubmit={this.submit.bind(this)}
          onCancel={this.cancelModal.bind(this)}
          onNext={this.next.bind(this)}
          size={this.state.step === 1 ? 'sm' : 'xl'}
          hasNextButton={this.state.step === 1 || this.state.step === 3}
          hasCloseButton={this.state.isDetail}
          hasSubmitButton={
            !this.state.isDetail &&
            this.state.step !== 1 &&
            this.state.step !== 3
          }
          hasCancelButton={!this.state.isDetail}
        >
          <>
            {this.state.step === 1 && (
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Kunde</Form.Label>
                    <Form.Select
                      name="customerId"
                      value={this.state.entry.customerId}
                      onChange={this.handleCustomerChange.bind(this)}
                      disabled={this.state.isDetail}
                    >
                      <option value={'new'}>Neuen Kunden erstellen</option>

                      {this.getAvailableCustomers().map((entry, index) => (
                        <option value={entry._id} key={index}>
                          {entry.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {this.state.availableLocations.length > 1 && (
                    <Form.Group className="mb-3">
                      <Form.Label>Geltungsbereich</Form.Label>
                      <Form.Select
                        name="locationId"
                        value={this.state.entry.locationId}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.state.isDetail}
                      >
                        {this.state.availableLocations.map((entry, index) => (
                          <option value={entry._id} key={index}>
                            {entry.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            )}
            {this.state.step === 2 && (
              <>
                <div className="d-none d-md-block">
                  <Row>
                    <Col sm={12} lg={4}>
                      {this.state.entry.customerId === 'new' && (
                        <Form.Group className="mb-3">
                          <Form.Label>Kundennummer</Form.Label>
                          <Form.Control
                            type="text"
                            name="customerNumber"
                            value={this.state.entry.customerNumber}
                            onChange={this.handleChange.bind(this)}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Bitte gebe eine Kundennummer ein.
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={this.state.entry.name}
                          onChange={this.handleChange.bind(this)}
                          disabled={this.state.isDetail}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Bitte gebe einen Namen ein.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Telefonnr.</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={this.state.entry.phone}
                          onChange={this.handleChange.bind(this)}
                          disabled={this.state.isDetail}
                        />
                        <Form.Control.Feedback type="invalid">
                          Bitte gebe eine Telefonnummer ein.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Fax</Form.Label>
                        <Form.Control
                          type="text"
                          name="fax"
                          value={this.state.entry.fax}
                          onChange={this.handleChange.bind(this)}
                          disabled={this.state.isDetail}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={this.state.entry.email}
                          onChange={this.handleChange.bind(this)}
                          disabled={this.state.isDetail}
                        />
                        <Form.Control.Feedback type="invalid">
                          Bitte gebe eine E-Mail Adresse ein.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={8}>
                      <div className="d-none d-md-block">
                        <Tabs
                          defaultActiveKey={0}
                          id="uncontrolled-tab-example"
                        >
                          {this.state.entry.sections.map((section, index) => (
                            <Tab
                              key={index}
                              eventKey={index}
                              title={section.name}
                            >
                              {section.fields.map((field, fieldIndex) => (
                                <div className="mt-3" key={fieldIndex}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="w-100">
                                      {field.name}
                                      {!this.state.isDetail && (
                                        <Button
                                          size={'sm'}
                                          variant={'secondary'}
                                          className="float-end"
                                          onClick={() =>
                                            this.removeField(index, fieldIndex)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            size={'sm'}
                                            icon={faTrash}
                                          />
                                        </Button>
                                      )}
                                    </Form.Label>
                                    {field.type === 'textarea' && (
                                      <TinymceEditor
                                        value={field.content}
                                        handleChange={value =>
                                          this.handleFieldValueChange(
                                            value,
                                            index,
                                            fieldIndex
                                          )
                                        }
                                        disabled={this.state.isDetail}
                                      />
                                    )}
                                    {field.type === 'text' && (
                                      <Form.Control
                                        type="text"
                                        value={field.content}
                                        onChange={e =>
                                          this.handleFieldValueChange(
                                            e.target.value,
                                            index,
                                            fieldIndex
                                          )
                                        }
                                        disabled={this.state.isDetail}
                                      />
                                    )}
                                    {field.type === 'checkbox' && (
                                      <Form.Check
                                        type="checkbox"
                                        label="Trifft zu"
                                        onChange={e =>
                                          this.handleFieldValueChange(
                                            e.target.checked,
                                            index,
                                            fieldIndex
                                          )
                                        }
                                        checked={field.content}
                                        disabled={this.state.isDetail}
                                      />
                                    )}
                                  </Form.Group>
                                </div>
                              ))}
                              <hr className="mt-3" />
                              {!this.state.isDetail && (
                                <Row className="mt-3">
                                  <Col sm={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        value={this.state.newField.name}
                                        onChange={this.handleFieldChange.bind(
                                          this
                                        )}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Typ</Form.Label>
                                      <Form.Select
                                        name="type"
                                        value={this.state.newField.type}
                                        onChange={this.handleFieldChange.bind(
                                          this
                                        )}
                                      >
                                        <option value="text">Text</option>
                                        <option value="textarea">
                                          Textarea
                                        </option>
                                        <option value="checkbox">
                                          Checkbox
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col sm={12}>
                                    <Button
                                      variant="primary"
                                      onClick={this.addField.bind(this, index)}
                                    >
                                      Hinzufügen
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            </Tab>
                          ))}
                        </Tabs>
                      </div>

                      <div className="d-block d-md-none">
                        <Accordion>
                          {this.state.entry.sections.map((section, index) => (
                            <Accordion.Item eventKey={index} key={index}>
                              <Accordion.Header>
                                {section.name}
                              </Accordion.Header>
                              <Accordion.Body>
                                {section.fields.map((field, fieldIndex) => (
                                  <div className="mt-3" key={fieldIndex}>
                                    <Form.Group className="mb-3">
                                      <Form.Label className="w-100">
                                        {field.name}
                                        {!this.state.isDetail && (
                                          <Button
                                            size={'sm'}
                                            variant={'secondary'}
                                            className="float-end"
                                            onClick={() =>
                                              this.removeField(
                                                index,
                                                fieldIndex
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              size={'sm'}
                                              icon={faTrash}
                                            />
                                          </Button>
                                        )}
                                      </Form.Label>
                                      {field.type === 'textarea' && (
                                        <TinymceEditor
                                          value={field.content}
                                          handleChange={value =>
                                            this.handleFieldValueChange(
                                              value,
                                              index,
                                              fieldIndex
                                            )
                                          }
                                          disabled={this.state.isDetail}
                                        />
                                      )}
                                      {field.type === 'text' && (
                                        <Form.Control
                                          type="text"
                                          value={field.content}
                                          onChange={e =>
                                            this.handleFieldValueChange(
                                              e.target.value,
                                              index,
                                              fieldIndex
                                            )
                                          }
                                          disabled={this.state.isDetail}
                                        />
                                      )}
                                      {field.type === 'checkbox' && (
                                        <Form.Check
                                          type="checkbox"
                                          label="Trifft zu"
                                          onChange={e =>
                                            this.handleFieldValueChange(
                                              e.target.checked,
                                              index,
                                              fieldIndex
                                            )
                                          }
                                          checked={field.content}
                                          disabled={this.state.isDetail}
                                        />
                                      )}
                                    </Form.Group>
                                  </div>
                                ))}
                                <hr className="mt-3" />
                                {!this.state.isDetail && (
                                  <Row className="mt-3">
                                    <Col sm={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          value={this.state.newField.name}
                                          onChange={this.handleFieldChange.bind(
                                            this
                                          )}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Typ</Form.Label>
                                        <Form.Select
                                          name="type"
                                          value={this.state.newField.type}
                                          onChange={this.handleFieldChange.bind(
                                            this
                                          )}
                                        >
                                          <option value="text">Text</option>
                                          <option value="textarea">
                                            Textarea
                                          </option>
                                          <option value="checkbox">
                                            Checkbox
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                      <Button
                                        variant="primary"
                                        onClick={this.addField.bind(
                                          this,
                                          index
                                        )}
                                      >
                                        Hinzufügen
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="d-block d-md-none">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="customer"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav
                          variant="pills"
                          style={{ overflowX: 'scroll', flexWrap: 'nowrap' }}
                          className="cds-pills"
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="customer">Allgemein</Nav.Link>
                          </Nav.Item>

                          {this.state.entry.sections.map((section, index) => (
                            <Nav.Item key={index}>
                              <Nav.Link eventKey={index}>
                                {section.name}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="customer">
                            {this.state.entry.customerId === 'new' && (
                              <Form.Group className="mb-3">
                                <Form.Label>Kundennummer</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="customerNumber"
                                  value={this.state.entry.customerNumber}
                                  onChange={this.handleChange.bind(this)}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  Bitte gebe eine Kundennummer ein.
                                </Form.Control.Feedback>
                              </Form.Group>
                            )}
                            <Form.Group className="mb-3">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={this.state.entry.name}
                                onChange={this.handleChange.bind(this)}
                                disabled={this.state.isDetail}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Bitte gebe einen Namen ein.
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Telefonnr.</Form.Label>
                              <Form.Control
                                type="text"
                                name="phone"
                                value={this.state.entry.phone}
                                onChange={this.handleChange.bind(this)}
                                disabled={this.state.isDetail}
                              />
                              <Form.Control.Feedback type="invalid">
                                Bitte gebe eine Telefonnummer ein.
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Fax</Form.Label>
                              <Form.Control
                                type="text"
                                name="fax"
                                value={this.state.entry.fax}
                                onChange={this.handleChange.bind(this)}
                                disabled={this.state.isDetail}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>E-Mail</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                value={this.state.entry.email}
                                onChange={this.handleChange.bind(this)}
                                disabled={this.state.isDetail}
                              />
                              <Form.Control.Feedback type="invalid">
                                Bitte gebe eine E-Mail Adresse ein.
                              </Form.Control.Feedback>
                            </Form.Group>
                            <hr />
                          </Tab.Pane>
                          {this.state.entry.sections.map((section, index) => (
                            <Tab.Pane eventKey={index} key={index}>
                              {section.fields.map((field, fieldIndex) => (
                                <div className="mt-3" key={fieldIndex}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="w-100">
                                      {field.name}
                                      {!this.state.isDetail && (
                                        <Button
                                          size={'sm'}
                                          variant={'secondary'}
                                          className="float-end"
                                          onClick={() =>
                                            this.removeField(index, fieldIndex)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            size={'sm'}
                                            icon={faTrash}
                                          />
                                        </Button>
                                      )}
                                    </Form.Label>
                                    {field.type === 'textarea' && (
                                      <TinymceEditor
                                        value={field.content}
                                        handleChange={value =>
                                          this.handleFieldValueChange(
                                            value,
                                            index,
                                            fieldIndex
                                          )
                                        }
                                        disabled={this.state.isDetail}
                                      />
                                    )}
                                    {field.type === 'text' && (
                                      <Form.Control
                                        type="text"
                                        value={field.content}
                                        onChange={e =>
                                          this.handleFieldValueChange(
                                            e.target.value,
                                            index,
                                            fieldIndex
                                          )
                                        }
                                        disabled={this.state.isDetail}
                                      />
                                    )}
                                    {field.type === 'checkbox' && (
                                      <Form.Check
                                        type="checkbox"
                                        label="Trifft zu"
                                        onChange={e =>
                                          this.handleFieldValueChange(
                                            e.target.checked,
                                            index,
                                            fieldIndex
                                          )
                                        }
                                        checked={field.content}
                                        disabled={this.state.isDetail}
                                      />
                                    )}
                                  </Form.Group>
                                </div>
                              ))}
                              <hr className="mt-3" />
                              {!this.state.isDetail && (
                                <Row className="mt-3">
                                  <Col sm={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        value={this.state.newField.name}
                                        onChange={this.handleFieldChange.bind(
                                          this
                                        )}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col sm={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Typ</Form.Label>
                                      <Form.Select
                                        name="type"
                                        value={this.state.newField.type}
                                        onChange={this.handleFieldChange.bind(
                                          this
                                        )}
                                      >
                                        <option value="text">Text</option>
                                        <option value="textarea">
                                          Textarea
                                        </option>
                                        <option value="checkbox">
                                          Checkbox
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col sm={12}>
                                    <Button
                                      variant="primary"
                                      onClick={this.addField.bind(this, index)}
                                    >
                                      Hinzufügen
                                    </Button>
                                    <hr />
                                  </Col>
                                </Row>
                              )}
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </>
            )}
            {this.state.step === 3 && (
              <>
                <Row>
                  <Col sm={12}>
                    {this.state.entry.customerId === 'new' && (
                      <Form.Group className="mb-3">
                        <Form.Label>Kundennummer</Form.Label>
                        <Form.Control
                          type="text"
                          name="customerNumber"
                          value={this.state.entry.customerNumber}
                          onChange={this.handleChange.bind(this)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Bitte gebe eine Kundennummer ein.
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={this.state.entry.name}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.state.isDetail}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gebe einen Namen ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Telefonnr.</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={this.state.entry.phone}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.state.isDetail}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gebe eine Telefonnummer ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Fax</Form.Label>
                      <Form.Control
                        type="text"
                        name="fax"
                        value={this.state.entry.fax}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.state.isDetail}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>E-Mail</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={this.state.entry.email}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.state.isDetail}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Bitte gebe eine E-Mail Adresse ein.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </>
        </ActionModal>
        <DeleteModal
          ref={this.deleteEntryModalRef}
          onDelete={() => this.deleteEntry()}
        />
      </div>
    );
  }
}

Cds.propTypes = {
  auth0: PropTypes.any
};

Cds.contextType = AuthProviderContext;

export default withAuth0(Cds);
